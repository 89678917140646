import { Layers, LayerSpec, Theme } from "./index";
import { AnyLayer } from "mapbox-gl";
import { Sources } from "../sources";

export function getActivitiesLayers(theme: Theme): LayerSpec[] {
  const minzoom = 9;

  const paths: AnyLayer = {
    id: Layers.ActivityLines,
    source: Sources.ActivityPaths,
    type: "line",
    minzoom,
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "darkgreen", // theme.lineColor,
      "line-width": 2,
    },
  };

  const icons: LayerSpec = {
    id: Layers.ActivityIcons,
    source: Sources.ActivityPoints,
    type: "symbol",
    minzoom,
    layout: {
      "icon-image": [
        "match",
        ["get", "type"],
        "hike",
        "shoe",
        "food+drink",
        "restaurant",
        "attraction",
      ],
      "icon-allow-overlap": true,
      "symbol-placement": "point",
      "icon-size": 1.25,
    },
  };

  const labels: LayerSpec = {
    id: Layers.ActivityLabels,
    source: Sources.ActivityPoints,
    type: "symbol",
    minzoom,
    layout: {
      "text-field": ["get", "title"],
      "text-size": 13,
      "text-offset": [0, -1.5],
    },
    paint: {
      "text-halo-color": "white",
      "text-halo-width": 1,
    },
  };

  return [paths, icons, labels];
}
