import * as arc from "arc";
import { JourneyGeometryProvider } from "./getJourneySourceData";

function greatCircleDistance(
  { lon: lon1, lat: lat1 }: { lon: number; lat: number },
  { lon: lon2, lat: lat2 }: { lon: number; lat: number }
): number {
  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // in metres
}

export const flightFeatureProvider: JourneyGeometryProvider = async (leg) => {
  const origin = leg.getOriginLocation();
  const destination = leg.getDestinationLocation();

  const flightPathGenerator = new arc.GreatCircle(
    { x: origin.lon, y: origin.lat },
    { x: destination.lon, y: destination.lat }
  );
  const flightPath: GeoJSON.Feature<GeoJSON.Geometry> = flightPathGenerator
    .Arc(64)
    .json();

  return {
    geometry: flightPath.geometry,
    distance: greatCircleDistance(origin, destination),
  };
};
