import BedIcon from "@mui/icons-material/Bed";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import FlightIcon from "@mui/icons-material/Flight";
import HikingIcon from "@mui/icons-material/Hiking";
import SearchIcon from "@mui/icons-material/Search";
import TrainIcon from "@mui/icons-material/Train";

import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Divider,
  IconButton,
  Link,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemButtonProps,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { LngLat, LngLatBounds, LngLatLike, Map, Popup } from "mapbox-gl";
import React, {
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import LazyLoad from "react-lazy-load";
import { Route, Routes, useParams } from "react-router-dom";
import { useAccount, useMap } from "../../../../App";
import InstagramEmbed from "../../../../components/InstagramEmbed";
import {
  BaseVisitModel,
  Coordinates,
  CountryVisitModel,
  JourneyLegType,
  RegionVisitModel,
  TourModel,
  TripSummaryModel,
  VisitModel,
} from "../../../../modules/model/v1";
import { slugify, TripRouteParams } from "../../../../modules/routing";
import { dateFormats } from "../../../../utils";
import { ExploreState, useExplore } from "./useExplore";
import useTrip from "./useTrip";
import { AccomClusterFeatureProperties } from "./useTrip/sources/accomClusters";
import { JourneyLegFeatureProperties } from "./useTrip/sources/journeys/getJourneySourceData";
import { TripEventHandlers, TripReadyState } from "./useTrip/useTrip";
import {
  ContentfulAccommodation,
  ContentfulActivity,
} from "../../../../modules/contentful/v1";

function useTripSummary(): TripSummaryModel {
  const { tripSlug } = useParams<TripRouteParams>();
  return useAccount()
    .getTripSummaries()
    .find((summary) => slugify(summary.getName()) === tripSlug);
}

interface HasLocationGetter {
  getLocation(): Coordinates;
}

function fitBounds(
  map: Map,
  ...items: Array<Array<HasLocationGetter | Coordinates>>
): void {
  const bounds = new LngLatBounds();
  const labels: any[] = [];

  items
    .flatMap((items) => {
      return items.map<Coordinates>((item) => {
        if ("getLocation" in item) {
          if ("type" in item && "getName" in item) {
            const visit = item as VisitModel;
            labels.push(`${visit.type}: ${visit.getName()}`);
          } else if (item instanceof ContentfulAccommodation) {
            labels.push(`accommodation: ${item.getName()}`);
          } else if (item instanceof ContentfulActivity) {
            labels.push(`activity: ${item.getTitle()}`);
          } else {
            labels.push(item);
          }
          return item.getLocation();
        } else {
          labels.push(item);
          return item;
        }
      });
    })
    .forEach((coords) => {
      bounds.extend(new LngLat(coords.lon, coords.lat));
    });

  console.log("map.fitBounds", labels);

  map.fitBounds(bounds, {
    padding: 128,
    maxZoom: 12,
  });
}

interface FeaturePopupProps {
  title: string;
  subtitle?: string;
  icon: React.ReactElement;
  coordinates: LngLatLike | number[];
  onClose: () => void;
}

function FeaturePopup({
  title,
  subtitle,
  icon,
  coordinates,
  onClose,
}: FeaturePopupProps) {
  const popupRef = useRef();
  const { map } = useMap();

  useEffect(() => {
    console.log("Adding popup");
    const popup = new Popup({})
      .setLngLat(coordinates as LngLatLike)
      .setDOMContent(popupRef.current)
      .addTo(map);

    popup.on("close", onClose);

    return () => {
      if (!popup.isOpen()) {
        return;
      }
      console.log("Cleaning up popup");
      popup.remove();
    };
  }, [coordinates, map, onClose]);

  return (
    <div style={{ display: "none" }}>
      <div ref={popupRef}>
        <Box display="flex" alignItems="center" p={1} bgcolor="white">
          <Box>
            <Typography pr={1}>{title}</Typography>
            {subtitle && (
              <Typography variant="body2" color="text.secondary">
                {subtitle}
              </Typography>
            )}
          </Box>
          <Box ml={2}>{icon}</Box>
        </Box>
      </div>
    </div>
  );
}

export default function TripV2Route(): React.ReactElement {
  const [popup, setPopup] = useState<React.ReactElement | null>(null);

  const eventHandlers = useMemo<TripEventHandlers>(
    () => ({
      onAccomClick(
        feature: GeoJSON.Feature<GeoJSON.Point, AccomClusterFeatureProperties>
      ): void {
        const { name, checkInDate, checkOutDate } = feature.properties;
        setPopup(
          <FeaturePopup
            title={name}
            subtitle={
              dateFormats.dateAndMonth(new Date(checkInDate)) +
              " - " +
              dateFormats.dateAndMonth(new Date(checkOutDate))
            }
            icon={<BedIcon />}
            coordinates={feature.geometry.coordinates}
            onClose={() => {
              console.log("Closing popup");
              setPopup(null);
            }}
          />
        );
      },
      onJourneyClick(
        feature: GeoJSON.Feature<
          GeoJSON.LineString,
          JourneyLegFeatureProperties
        >,
        event
      ): void {
        const { title, type, startDate } = feature.properties;

        const icons: { [type in JourneyLegType]: React.ReactElement } = {
          flight: <FlightIcon />,
          ferry: <DirectionsBoatIcon />,
          car: <DirectionsCarIcon />,
          train: <TrainIcon />,
          foot: <HikingIcon />,
        };

        setPopup(
          <FeaturePopup
            title={title}
            subtitle={dateFormats.dateAndMonth(new Date(startDate))}
            icon={icons[type]}
            coordinates={event.lngLat}
            onClose={() => {
              console.log("Closing popup");
              setPopup(null);
            }}
          />
        );
      },
    }),
    []
  );

  const tripSummary = useTripSummary();
  const trip = useTrip(tripSummary.getId(), eventHandlers);

  switch (trip.state) {
    case "pending":
    case "loading":
      return <Typography>Loading...</Typography>;

    case "error":
      return <Typography>{trip.error.message}</Typography>;

    default:
    case "ready":
      return (
        <>
          {popup}
          <Routes>
            <Route index element={<TourExplorer trip={trip} />} />
          </Routes>
        </>
      );
  }
}

// https://flagcdn.com/en/codes.json
const CountryCodes: Record<string, string | undefined> = {
  indonesia: "id",
  malaysia: "my",
  thailand: "th",
  vietnam: "vn",
  cambodia: "kh",
};

function visitKey(visit: BaseVisitModel) {
  return visit.getName() + visit.getArrivalDate().getTime();
}

function VisitListItemButton({
  visit,
  navigate,
  children,
  avatar,
  ...props
}: {
  visit: VisitModel;
  navigate: (visit: VisitModel) => void;
  avatar?: React.ReactElement;
  children?: React.ReactElement;
} & ListItemButtonProps) {
  return (
    <ListItemButton onClick={() => navigate(visit)} {...props}>
      {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
      {children || (
        <ListItemText
          primary={visit.getName()}
          secondary={visit.getArrivalDate().toDateString()}
        />
      )}
    </ListItemButton>
  );
}

function countryList(tour: TourModel, explore: ExploreState): ReactElement {
  return (
    <List>
      {tour.getCountryVisits().map((visit) => {
        let avatar: React.ReactElement;

        const countryCodeKey = visit.getName().toLowerCase();
        if (countryCodeKey in CountryCodes) {
          const code = CountryCodes[countryCodeKey];
          avatar = (
            <img
              src={`https://flagcdn.com/48x36/${code}.png`}
              srcSet={`https://flagcdn.com/72x54/${code}.png 2x,
  https://flagcdn.com/48x36/${code}.png 3x`}
              width="24"
              height="18"
              alt={visit.getName()}
            ></img>
          );
        }

        return (
          <VisitListItemButton
            key={visitKey(visit)}
            visit={visit}
            navigate={explore.navigate}
            avatar={avatar}
          />
        );
      })}
    </List>
  );
}

function regionList(
  countryVisit: CountryVisitModel,
  explore: ExploreState
): ReactElement {
  return (
    <List>
      {countryVisit.getRegionVisits().map((regionVisit) => (
        <Fragment key={visitKey(regionVisit)}>
          <VisitListItemButton
            visit={regionVisit}
            navigate={explore.navigate}
          />
          {regionVisit.getDestinationVisits().map((destinationVisit) => (
            <VisitListItemButton
              key={visitKey(destinationVisit)}
              navigate={explore.navigate}
              visit={destinationVisit}
              sx={{ pl: 4 }}
            />
          ))}
        </Fragment>
      ))}
    </List>
  );
}

function destinationList(
  parentVisit: CountryVisitModel | RegionVisitModel,
  explore: ExploreState
): ReactElement {
  return (
    <List>
      {parentVisit.getDestinationVisits().map((visit) => (
        <VisitListItemButton
          key={visitKey(visit)}
          visit={visit}
          navigate={explore.navigate}
        />
      ))}
    </List>
  );
}

function TourExplorer({ trip }: { trip: TripReadyState }) {
  const tour = useMemo(() => trip.model.getTour(), [trip]);
  const explore = useExplore(trip);
  const { map, resetMapPosition } = useMap();
  const [showSearch, setShowSearch] = useState<boolean>(false);

  useEffect(() => {
    if (!explore.activeVisit) {
      return trip.helpers.fitBounds();
    }

    fitBounds(
      map,
      explore.activeVisit.getAccommodations(),
      explore.activeVisit.getActivities()
    );
  }, [explore, trip, map, resetMapPosition]);

  let content: React.ReactElement;

  if (!explore.activeVisit) {
    if (tour.getCountryVisits().length > 1) {
      content = countryList(tour, explore);
    } else {
      const countryVisit = tour.getCountryVisits()[0];

      if (countryVisit.getRegionVisits().length > 1) {
        content = regionList(countryVisit, explore);
      } else if (countryVisit.getRegionVisits().length === 1) {
        content = destinationList(countryVisit.getRegionVisits()[0], explore);
      } else {
        content = destinationList(countryVisit, explore);
      }
    }
  } else {
    switch (explore.activeVisit.type) {
      case "country":
        const countryVisit = explore.activeVisit;

        if (countryVisit.getRegionVisits().length > 0) {
          content = regionList(countryVisit, explore);
        } else {
          content = destinationList(countryVisit, explore);
        }

        break;

      case "region":
        const regionVisit = explore.activeVisit;

        content = destinationList(regionVisit, explore);

        break;

      case "destination":
        const destinationVisit = explore.activeVisit;

        content = (
          <Box>
            {destinationVisit.getJournalEntries().map((journalEntry) => (
              <Fragment key={journalEntry.getDate().toDateString()}>
                <Box py={2}>
                  <Typography variant="h6">
                    {journalEntry.getDate().toDateString()}
                  </Typography>
                  {journalEntry.getInstagramPosts().map((post) => (
                    <LazyLoad
                      key={post.getUrl()}
                      offset={300}
                      onContentVisible={() => console.log("Rendered")}
                    >
                      <InstagramEmbed url={post.getUrl()} />
                    </LazyLoad>
                  ))}
                </Box>
                <Divider />
              </Fragment>
            ))}
          </Box>
        );

        break;
    }
  }

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {explore.activeVisit ? (
          <Breadcrumbs>
            {explore.breadcrumbs.map((crumb) =>
              crumb.onClick ? (
                <Link
                  key={crumb.title}
                  component="button"
                  variant="body1"
                  onClick={crumb.onClick}
                >
                  {crumb.title}
                </Link>
              ) : (
                <Typography key={crumb.title}>{crumb.title}</Typography>
              )
            )}
          </Breadcrumbs>
        ) : (
          <Typography variant="h6">
            {trip.model.getSummary().getName()}
          </Typography>
        )}
        <IconButton
          aria-label="search"
          onClick={() => setShowSearch(!showSearch)}
        >
          <SearchIcon />
        </IconButton>
      </Box>
      {showSearch && (
        <Box>
          <Autocomplete
            options={explore.allVisits.sort((a, b) =>
              a.type.localeCompare(b.type)
            )}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.getName()
            }
            groupBy={(option) => option.type}
            onChange={(_, visit) =>
              typeof visit === "string" ? null : explore.navigate(visit)
            }
            renderInput={(params) => <TextField {...params} label="Search" />}
            renderOption={(props, option) => (
              <Box
                component="li"
                {...props}
                key={option.getName() + option.getArrivalDate()}
              >
                <Typography>{option.getName()}</Typography>
                <Typography sx={{ color: "text.secondary", pl: 1 }}>
                  {option.getArrivalDate().getDate() +
                    "/" +
                    option.getArrivalDate().getMonth()}
                </Typography>
              </Box>
            )}
          />
        </Box>
      )}
      {content}
    </div>
  );
}

// function useRowState(tour: TourModel): {
//   isOpen: (node: CountryVisitModel | RegionVisitModel) => boolean;
//   toggleCountry: (node: CountryVisitModel) => void;
//   toggleRegion: (node: RegionVisitModel) => void;
// } {
//   function hashVisit(
//     visit: CountryVisitModel | RegionVisitModel | DestinationVisitModel
//   ) {
//     return visit.getName() + visit.getArrivalDate().toISOString();
//   }

//   const [openRows, setOpenRows] = useState(() => {
//     const allRows = new Set();
//     tour.getCountryVisits().forEach((countryVisit) => {
//       allRows.add(hashVisit(countryVisit));

//       countryVisit.getRegionVisits().forEach((regionVisit) => {
//         allRows.add(hashVisit(regionVisit));
//       });
//     });

//     return allRows;
//   });

//   function toggleRow(key: string) {
//     const nextOpenRows = new Set(openRows);

//     if (openRows.has(key)) {
//       nextOpenRows.delete(key);
//     } else {
//       nextOpenRows.add(key);
//     }

//     setOpenRows(nextOpenRows);
//   }

//   function isOpen(visit: CountryVisitModel | RegionVisitModel) {
//     return openRows.has(hashVisit(visit));
//   }

//   function toggleCountry(countryVisit: CountryVisitModel) {
//     toggleRow(hashVisit(countryVisit));
//   }

//   function toggleRegion(regionVisit: RegionVisitModel) {
//     toggleRow(hashVisit(regionVisit));
//   }

//   return {
//     isOpen,
//     toggleCountry,
//     toggleRegion,
//   };
// }

// function DestinationListHeirarchy({ trip }: { trip: TripModel }) {
//   const tour = useMemo(() => trip.getTour(), [trip]);
//   const { isOpen, toggleCountry, toggleRegion } = useRowState(tour);
//   const { map } = useMap();

//   const rows: Array<{
//     visit: BaseVisitModel;
//     indent: number;
//     isOpen?: boolean;
//     onToggleClick?: () => void;
//     onTitleClick?: () => void;
//   }> = [];

//   tour.getCountryVisits().forEach((countryVisit) => {
//     rows.push({
//       visit: countryVisit,
//       indent: 0,
//       isOpen: isOpen(countryVisit),
//       onToggleClick: () => toggleCountry(countryVisit),
//       onTitleClick: () => fitBounds(map, countryVisit.getAccommodations()),
//     });

//     if (!isOpen(countryVisit)) {
//       return;
//     }

//     if (countryVisit.getRegionVisits().length > 0) {
//       countryVisit.getRegionVisits().forEach((regionVisit) => {
//         rows.push({
//           visit: regionVisit,
//           indent: 1,
//           isOpen: isOpen(regionVisit),
//           onToggleClick: () => toggleRegion(regionVisit),
//           onTitleClick: () => fitBounds(map, regionVisit.getAccommodations()),
//         });

//         if (!isOpen(regionVisit)) {
//           return;
//         }

//         regionVisit.getDestinationVisits().forEach((destinationVisit) => {
//           rows.push({
//             visit: destinationVisit,
//             indent: 2,
//             onTitleClick: () =>
//               fitBounds(map, destinationVisit.getAccommodations()),
//           });
//         });
//       });
//     } else {
//       countryVisit.getDestinationVisits().forEach((destinationVisit) => {
//         rows.push({
//           visit: destinationVisit,
//           indent: 2,
//           onTitleClick: () =>
//             fitBounds(map, destinationVisit.getAccommodations()),
//         });
//       });
//     }
//   });

//   return (
//     <>
//       {rows.map((row, i) => (
//         <div
//           key={row.visit.getName() + i}
//           style={{ display: "flex", padding: "1em 0" }}
//         >
//           <div style={{ flex: "0 0 3em" }}>
//             {(row.isOpen === undefined || !row.isOpen) &&
//               `${row.visit.getArrivalDate().getDate()}/${
//                 row.visit.getArrivalDate().getMonth() + 1
//               }`}
//           </div>
//           <div>
//             <div style={{ paddingLeft: row.indent + "em" }}>
//               <span onClick={row.onTitleClick}>{row.visit.getName()}</span>
//               {row.isOpen !== undefined && (
//                 <span onClick={row.onToggleClick}>
//                   {row.isOpen ? <>&#8963;</> : <>&#8964;</>}
//                 </span>
//               )}
//             </div>
//           </div>
//         </div>
//       ))}
//     </>
//   );
// }
