import mbxDirections, {
  Polyline,
  Route as DirectionsRoute,
  Waypoint as DirectionsWaypoint,
} from "@mapbox/mapbox-sdk/services/directions";
import { decode } from "@mapbox/polyline";
import { MAPBOX_ACCESS_TOKEN } from "../../../../../../../clients/mapbox";
import { cacheGet } from "../../../../../../../modules/cache";
import { JourneyGeometryProvider } from "./getJourneySourceData";

const directionsClient = mbxDirections({ accessToken: MAPBOX_ACCESS_TOKEN });

export const carFeatureProvider: JourneyGeometryProvider = async (leg) => {
  const origin = leg.getOriginLocation();
  const destination = leg.getDestinationLocation();
  const waypoints = leg.getWaypointLocations();
  const routeWaypoints: DirectionsWaypoint[] = [];

  routeWaypoints.push({
    coordinates: [origin.lon, origin.lat],
  });

  waypoints.forEach((waypoint) =>
    routeWaypoints.push({
      coordinates: [waypoint.lon, waypoint.lat],
    })
  );

  routeWaypoints.push({
    coordinates: [destination.lon, destination.lat],
  });

  const route = await cacheGet<Promise<DirectionsRoute<Polyline>>>(
    [origin.lat, origin.lon, destination.lat, destination.lon].join(","),
    async () => {
      const directionsResponse = await directionsClient
        .getDirections({
          profile: "driving",
          waypoints: routeWaypoints,
        })
        .send();

      if (directionsResponse.body.code !== "Ok") {
        console.error("Bad route", directionsResponse.body);
        throw new Error("Bad route");
      }

      return directionsResponse.body.routes[0];
    }
  );

  const geometry: GeoJSON.LineString = {
    type: "LineString",
    coordinates: decode(route.geometry, 5).map((c) => c.reverse()),
  };

  if (!geometry.coordinates.length) {
    console.warn("Bad route");
  }

  return {
    geometry,
    distance: route.distance,
  };
};
