import { LngLat, LngLatBounds } from "mapbox-gl";

export const locationToPosition = (location: {
  lon: number;
  lat: number;
}): [number, number] => [location.lon, location.lat];

export const getBounds = (locations: Array<{ lon: number; lat: number }>) => {
  const bounds = new LngLatBounds(locations[0], locations[0]);

  locations.forEach(({ lon, lat }) => {
    bounds.extend(new LngLat(lon, lat));
  });

  return bounds;
};

export const dateFormats = {
  dateAndMonth: (date: Date) =>
    date.getDate().toString().padStart(2, "0") +
    "/" +
    date.getMonth().toString().padStart(2, "0"),
};
