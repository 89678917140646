import localforage from "localforage";

interface CacheOptions {
  disable?: boolean;
}

export function isCachingEnabled() {
  return localStorage.getItem("disableCache") === null;
}

// Used by contentful client, backed by  IndexedDB
export const forageStore = localforage.createInstance({
  // Prefix all storage keys to prevent conflicts
  name: "contentful",
});

export function cacheGet<T>(key: string, supplier: () => T): T;
export function cacheGet<T>(
  key: string,
  options: CacheOptions,
  supplier: () => T
): T;
export function cacheGet<T>(
  key: string,
  supplierOrOptions: any,
  supplierOrUndef?: any
): T {
  const supplier: () => T =
    supplierOrUndef === undefined ? supplierOrOptions : supplierOrUndef;
  const options: CacheOptions =
    supplierOrUndef === undefined ? {} : supplierOrOptions;

  const disabled = !isCachingEnabled() || options.disable;

  if (!disabled) {
    const cachedValue = localStorage.getItem(key);

    if (cachedValue !== null) {
      // console.debug("cache-hit", key);
      return JSON.parse(cachedValue);
    }
  }

  // console.debug("cache-miss", key);
  const result = supplier();

  if (result instanceof Promise) {
    return result.then((data: T) => {
      if (!disabled) {
        localStorage.setItem(key, JSON.stringify(data));
      }
      return data;
    }) as any;
  }

  if (!disabled) {
    localStorage.setItem(key, JSON.stringify(result));
  }

  return result;
}
