import {
  ActivityModel,
  ActivityType,
  HikeActivityModel,
  TripModel,
} from "../../../../../../modules/model/v1";
import { AnySourceData } from "mapbox-gl";
import { Sources } from "./index";

export function getActivitySources(
  trip: TripModel
): Record<string, AnySourceData> {
  return {
    [Sources.ActivityPaths]: getActivityPaths(trip),
    [Sources.ActivityPoints]: getActivityPoints(trip),
  };
}

function getActivityPoints(trip: TripModel): AnySourceData {
  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: trip.getActivities().map((activity) => {
        const { lon, lat } = activity.getLocation();

        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [lon, lat],
          },
          properties: {
            type: activity.getType(),
            title: activity.getTitle(),
          },
        };
      }),
    },
  };
}

function isHike(activity: ActivityModel): activity is HikeActivityModel {
  return activity.getType() === ActivityType.HIKE;
}
function getActivityPaths(trip: TripModel): AnySourceData {
  const activities: HikeActivityModel[] = trip.getActivities().filter(isHike);

  return {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: activities.map((activity) => ({
        type: "Feature",
        geometry: activity.getPathGeometry(),
        properties: {
          title: activity.getTitle(),
          type: activity.getType(),
        },
      })),
    },
  };
}
