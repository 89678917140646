import { gql, useQuery } from "@apollo/client";
import { Button } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router";
import { useAccount, useMap } from "../../../App";
import { TripCard } from "../../../components/TripCard";
import { TripSummaryModel } from "../../../modules/model/v1";
import { slugify, TripRouteParams } from "../../../modules/routing";
import { Explorer } from "./Explorer";
import { renderTrip } from "./renderTrip";
import {
  TripRouteQuery,
  TripRouteQueryVariables,
  TripRouteQuery_journalEntryCollection_items,
} from "./__generated__/TripRouteQuery";

function useTripSummary(): TripSummaryModel {
  const { tripSlug } = useParams<TripRouteParams>();
  const account = useAccount();
  return useMemo(
    () =>
      account
        .getTripSummaries()
        .find((summary) => slugify(summary.getName()) === tripSlug),
    [account, tripSlug]
  );
}

const TRIP_ROUTE_QUERY = gql`
  query TripRouteQuery($tripId: String!) {
    journalEntryCollection(
      where: { trip: { sys: { id: $tripId } } }
      order: date_ASC
    ) {
      items {
        sys {
          id
        }
        date
        accommodation {
          sys {
            id
          }
          name
          checkIn
          checkOut
          location {
            lat
            lon
          }
          locationRef {
            sys {
              id
            }
          }
          parking {
            lat
            lon
          }
          waypointsCollection {
            items {
              sys {
                id
              }
              name
              location {
                lat
                lon
              }
              direct
            }
          }
        }
      }
    }

    activityCollection(where: { trip: { sys: { id: $tripId } } }) {
      items {
        sys {
          id
        }
        type
        location {
          lat
          lon
        }
      }
    }
  }
`;

export default function TripV1Route(): any {
  const tripSummary = useTripSummary();

  const [ready, setReady] = useState(false);
  const navigate = useNavigate();

  const map = useMap();

  const { data, loading, error } = useQuery<
    TripRouteQuery,
    TripRouteQueryVariables
  >(TRIP_ROUTE_QUERY, { variables: { tripId: tripSummary.getId() } });

  if (error) {
    throw error;
  }

  useEffect(() => {
    if (loading) {
      return;
    }

    const trip = renderTrip(map, data);
    trip.ready.then(() => setReady(true));

    return trip.reset;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripSummary, loading]);

  if (loading || !ready) {
    return null;
  }

  console.log({ tripSummary, loading, ready });

  const journal = data.journalEntryCollection.items;
  const firstEntry = journal[0];
  const entryUrl = (entry: TripRouteQuery_journalEntryCollection_items) =>
    DateTime.fromISO(entry.date, {
      zone: "utc",
    }).toISODate();

  return (
    <>
      <Routes>
        <Route
          path=":entryDate"
          element={
            <Explorer
              journal={data.journalEntryCollection.items}
              navigate={(entryId) =>
                navigate(
                  entryUrl(journal.find((entry) => entry.sys.id === entryId))
                )
              }
            />
          }
        />
        <Route
          index
          element={
            <TripCard
              tripId={tripSummary.getId()}
              actions={
                <Button
                  size="small"
                  data-joyride="go-to-day-one"
                  onClick={() => navigate(entryUrl(firstEntry))}
                >
                  Go to Day 1
                </Button>
              }
            />
          }
        />
      </Routes>
    </>
  );
}
