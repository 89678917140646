import { AnyLayer } from "mapbox-gl";
import { Layers, LayerSpec, Theme } from ".";
import { Sources } from "../sources";

export function getAccomClusterLayers(theme: Theme): LayerSpec[] {
  const clusterCircles: AnyLayer = {
    id: Layers.AccomClusterCircles,
    source: Sources.AccomClusters,
    // filter: ["==", ["get", "type"], "accom"],
    filter: ["has", "cluster"],
    type: "circle",
    layout: {},
    paint: {
      "circle-color": theme.accomColor,
      "circle-opacity": 0.66,
      "circle-radius": [
        "interpolate",
        ["linear"],
        ["get", "point_count"],
        // 12px for 2pts -> 20px for 10pts
        2,
        14,
        10,
        20,
      ],
    },
  };

  const clusterLabels: AnyLayer = {
    id: Layers.AccomClusterLabels,
    source: Sources.AccomClusters,
    filter: ["has", "cluster"],
    type: "symbol",
    layout: {
      "text-field": "{minOrder}-{maxOrder}",
      "text-size": 11,
    },
    paint: {
      "text-color": "white",
    },
  };

  // const circles: AnyLayer = {
  //   id: Layers.AccomCircles,
  //   source: Sources.AccomClusters,
  //   filter: ["!", ["has", "cluster"]],
  //   type: "circle",
  //   paint: {
  //     "circle-color": theme.accomColor,
  //     "circle-radius": 10,
  //   },
  // };

  const icons: AnyLayer = {
    id: Layers.AccomCircles,
    source: Sources.AccomClusters,
    filter: ["!", ["has", "cluster"]],
    type: "symbol",
    layout: {
      "icon-image": "lodging",
      "icon-size": 1.25,
    },
  };

  // const labels: AnyLayer = {
  //   id: Layers.AccomLabels,
  //   source: Sources.AccomClusters,
  //   filter: ["!", ["has", "cluster"]],
  //   type: "symbol",
  //   layout: {
  //     "text-field": "{order}",
  //     "text-size": 12,
  //   },
  //   paint: {
  //     "text-color": "white",
  //   },
  // };

  return [clusterCircles, clusterLabels, icons];
}
