import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { Document } from "@contentful/rich-text-types";
import { Card, CardContent, Typography } from "@mui/material";
import { ExplorerQuery_journalEntry_activitiesCollection_items } from "./__generated__/ExplorerQuery";

interface ActivityCardProps {
  activity: ExplorerQuery_journalEntry_activitiesCollection_items;
}

export function ActivityCard({ activity }: ActivityCardProps) {
  const { title, description } = activity;

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6">{title}</Typography>
        {description && (
          <div
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(description.json as any as Document),
            }}
          />
        )}
      </CardContent>
    </Card>
  );
}
