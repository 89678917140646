import {
  AnyLayer,
  AnySourceData,
  GeoJSONSource,
  LngLat,
  LngLatBounds,
  Map,
  MapMouseEvent,
} from "mapbox-gl";
import { useEffect, useState } from "react";
import { useMap } from "../../../../../App";

import { ContentfulModelProvider } from "../../../../../modules/contentful/v1";
import { ModelProvider, TripModel } from "../../../../../modules/model/v1";
import { Layers, LayerSpec, TangerineTheme } from "./layers";
import { getAccomClusterLayers } from "./layers/accomClusters";
import { getJourneyLayers } from "./layers/journeys";
import { Sources } from "./sources";
import {
  AccomClusterFeatureProperties,
  getAccomClusterSourceData,
} from "./sources/accomClusters";
import {
  getJourneySourceData,
  JourneyLegFeatureProperties,
} from "./sources/journeys/getJourneySourceData";
import { getActivitySources } from "./sources/activities";
import { getActivitiesLayers } from "./layers/activities";

interface TripHelpers {
  cleanup: () => void;
  fitBounds: () => void;
}

export interface TripEventHandlers {
  onAccomClick(
    feature: GeoJSON.Feature<GeoJSON.Point, AccomClusterFeatureProperties>,
    event: MapMouseEvent
  ): void;
  onJourneyClick(
    feature: GeoJSON.Feature<GeoJSON.Geometry, JourneyLegFeatureProperties>,
    event: MapMouseEvent
  ): void;
}

const theme = TangerineTheme;

async function renderTrip(
  trip: TripModel,
  map: Map,
  eventHandlers: TripEventHandlers
): Promise<TripHelpers> {
  const cleanup = {
    sources: new Set<string>(),
    layers: new Set<string>(),
  };

  function addSource(id: string, data: AnySourceData) {
    console.groupCollapsed(`source:${id}`, data);

    const source = map.getSource(id);
    if (!source) {
      console.debug("Adding new source");
      map.addSource(id, data);
      cleanup.sources.add(id);
    } else if (data.type === "geojson") {
      console.debug("Updating gejson data");
      (source as GeoJSONSource).setData(data.data);
    } else {
      console.error("Cannot addSource", id);
    }

    console.groupEnd();
  }

  function addLayer(spec: LayerSpec) {
    let layer: AnyLayer;
    let beforeLayerId: string | undefined = undefined;

    if (Array.isArray(spec)) {
      layer = spec[0];
      beforeLayerId = spec[1];
    } else {
      layer = spec;
    }

    console.groupCollapsed(`layer:${layer.id}`, layer);

    if (map.getLayer(layer.id)) {
      console.debug("Removing existing layer");
      map.removeLayer(layer.id);
    }

    map.addLayer(layer, beforeLayerId);
    cleanup.layers.add(layer.id);

    console.groupEnd();
  }

  // console.debug("trip", {
  //   journeys: trip.getJourneys().map((journey) => ({
  //     title: journey.getTitle(),
  //     legs: journey.getLegs().map((leg) => ({
  //       title: leg.getTitle(),
  //       type: leg.getType(),
  //       startDate: leg.getStartDate(),
  //       originLocation: leg.getOriginLocation(),
  //       endDate: leg.getEndDate(),
  //       destinationLocation: leg.getDestinationLocation(),
  //       waypointLocations: leg.getWaypointLocations(),
  //       pathData: leg.getPathData(),
  //     })),
  //   })),
  // });

  addSource(Sources.Journeys, await getJourneySourceData(trip));

  getJourneyLayers(theme).forEach(addLayer);

  map.on("click", [Layers.JourneyLine], (event) => {
    eventHandlers.onJourneyClick(
      event.features[0] as unknown as GeoJSON.Feature<
        GeoJSON.LineString,
        JourneyLegFeatureProperties
      >,
      event
    );
  });

  Object.entries(getActivitySources(trip)).forEach(([id, data]) =>
    addSource(id, data)
  );
  getActivitiesLayers(theme).forEach(addLayer);

  addSource(Sources.AccomClusters, await getAccomClusterSourceData(trip));

  getAccomClusterLayers(theme).forEach(addLayer);

  map.on("click", [Layers.AccomCircles, Layers.AccomLabels], (event) => {
    eventHandlers.onAccomClick(
      event.features[0] as unknown as GeoJSON.Feature<
        GeoJSON.Point,
        AccomClusterFeatureProperties
      >,
      event
    );
  });

  map.on("mouseenter", [Layers.AccomCircles, Layers.JourneyLine], () => {
    map.getCanvas().style.cursor = "pointer";
  });

  // Change it back to a pointer when it leaves.
  map.on("mouseleave", [Layers.AccomCircles, Layers.JourneyLine], () => {
    map.getCanvas().style.cursor = "";
  });

  return {
    cleanup() {
      console.groupCollapsed("Trip cleanup");
      cleanup.layers.forEach((id) => {
        if (map.getLayer(id)) {
          console.debug("Removing layer", id);
          map.removeLayer(id);
        } else {
          console.debug("Skipping layer", id);
        }
      });
      // cleanup.sources.forEach((id) => {
      //   if (map.getSource(id)) {
      //     console.debug("Removing source", id);
      //     map.removeSource(id);
      //   } else {
      //     console.debug("Skipping source", id);
      //   }
      // });
      console.groupEnd();
    },
    fitBounds() {
      const journeyLegs = trip
        .getJourneys()
        .flatMap((journey) => journey.getLegs());

      const viewport = new LngLatBounds();

      journeyLegs.forEach((leg) => {
        if (leg.fitOriginToMap()) {
          const origin = leg.getOriginLocation();
          viewport.extend(new LngLat(origin.lon, origin.lat));
        }

        if (leg.fitDestinationToMap()) {
          const destination = leg.getDestinationLocation();
          viewport.extend(new LngLat(destination.lon, destination.lat));
        }
      });

      map.fitBounds(viewport, {
        padding: 96,
      });
    },
  };
}

export interface TripReadyState {
  state: "ready";
  model: TripModel;
  helpers: TripHelpers;
}
export type TripState =
  | { state: "pending" }
  | { state: "loading"; promise: Promise<any> }
  | TripReadyState
  | { state: "error"; error: Error };

export function useTrip(
  tripId: string,
  eventHandlers: TripEventHandlers
): TripState {
  const [tripState, setTripState] = useState<TripState>({ state: "pending" });
  const { map } = useMap();

  useEffect(() => {
    async function renderTripAsync() {
      const provider: ModelProvider = new ContentfulModelProvider();
      const trip = await provider.getTrip(tripId);

      console.groupCollapsed("Rendering trip");

      renderTrip(trip, map, eventHandlers)
        .then((helpers) => {
          console.log("Trip rendered");
          console.groupEnd();

          setTripState({
            state: "ready",
            model: trip,
            helpers,
          });
        })
        .catch((error) => {
          console.groupEnd();
          console.error("Failed to render trip", error);
          setTripState({ state: "error", error });
        });
    }

    if (tripState.state === "pending") {
      setTripState({ state: "loading", promise: renderTripAsync() });
    }

    return () => {
      if (tripState.state === "ready") {
        tripState.helpers.cleanup();
        setTripState({ state: "pending" });
      }
    };
  }, [tripState, map, tripId, eventHandlers]);

  return tripState;
}
