import { cacheGet } from "./cache";
import { DOMParser as XMLDOMParser } from "@xmldom/xmldom";
import { gpx } from "@tmcw/togeojson";

export function gpxToGeoJSON(
  cacheKey: string,
  xmlString: string
): GeoJSON.Geometry {
  return cacheGet(`geojson.${cacheKey}`, () => {
    const dom = new XMLDOMParser().parseFromString(xmlString, "text/xml");

    const geojson = gpx(dom);
    // console.log(fetchedAsset.asset.fields.file.fileName, geojson);

    if (geojson.features.every((f) => f.geometry.type === "MultiLineString")) {
      const features = geojson.features as Array<
        GeoJSON.Feature<GeoJSON.MultiLineString>
      >;

      return {
        type: "MultiLineString",
        coordinates: features.flatMap(
          (feature) => feature.geometry.coordinates
        ),
      };
    }

    return geojson.features[0].geometry;
  });
}
