import { AnySourceData } from "mapbox-gl";
import {
  JourneyLegModel,
  JourneyLegType,
  TripModel,
} from "../../../../../../../modules/model/v1";
import { carFeatureProvider } from "./carFeatureProvider";
import { emptyFeatureProvider } from "./emptyFeatureProvider";
import { flightFeatureProvider } from "./flightFeatureProvider";

export interface JourneyGeometry {
  geometry: GeoJSON.Geometry;
  distance?: number;
}
export interface JourneyGeometryProvider {
  (leg: JourneyLegModel): Promise<JourneyGeometry>;
}

const journeyGeometryProviders: {
  [type in JourneyLegType]: JourneyGeometryProvider;
} = {
  [JourneyLegType.FLIGHT]: flightFeatureProvider,
  [JourneyLegType.CAR]: carFeatureProvider,
  [JourneyLegType.TRAIN]: emptyFeatureProvider,
  [JourneyLegType.FOOT]: emptyFeatureProvider,
  [JourneyLegType.FERRY]: emptyFeatureProvider,
};

export interface JourneyLegFeatureProperties {
  type: JourneyLegType;
  title: string;
  startDate: string;
  endDate: string;
  distance?: number;
}

export async function getJourneySourceData(
  trip: TripModel
): Promise<AnySourceData> {
  const journeyLegs = trip
    .getJourneys()
    .flatMap((journey) => journey.getLegs());

  const journeyData: GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    JourneyLegFeatureProperties
  > = {
    type: "FeatureCollection",
    features: await Promise.all(
      journeyLegs.map(async (leg) => {
        const properties: JourneyLegFeatureProperties = {
          type: leg.getType(),
          title: leg.getTitle(),
          startDate: leg.getStartDate().toISOString(),
          endDate: leg.getStartDate().toISOString(),
        };

        const pathData = leg.getPathGeometry();
        let geometry: GeoJSON.Geometry;

        if (pathData !== undefined) {
          geometry = pathData;
        } else {
          const providerResult = await journeyGeometryProviders[
            leg.getType()
          ].call(null, leg);

          geometry = providerResult.geometry;

          if (providerResult.distance !== undefined) {
            properties.distance = providerResult.distance;
          }
        }

        return {
          type: "Feature",
          geometry,
          properties,
        } as GeoJSON.Feature<GeoJSON.Geometry, JourneyLegFeatureProperties>;
      })
    ),
  };

  return {
    type: "geojson",
    data: journeyData,
  };
}
