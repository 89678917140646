import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import {
  BaseVisitModel,
  RegionVisitModel,
  VisitModel,
} from "../../../../modules/model/v1";
import { TripReadyState } from "./useTrip/useTrip";

interface Breadcrumb {
  title: string;
  onClick?: () => void;
  // visitNode: VisitNode;
}

export interface ExploreState {
  activeVisit?: VisitModel;
  allVisits: VisitModel[];
  breadcrumbs: Breadcrumb[];
  navigate: (visit: VisitModel | undefined) => void;
}

function visitNameParamValue(visit: BaseVisitModel) {
  return visit.getName().trim().toLowerCase();
}

function arrivalDataParamValue(visit: BaseVisitModel) {
  return visit.getArrivalDate().toISOString().split("T")[0];
}

function isVisitMatch(visit: BaseVisitModel, searchParams: URLSearchParams) {
  if (!searchParams.has(visit.type)) {
    return false;
  }

  return (
    visitNameParamValue(visit) ===
      searchParams.get(visit.type).trim().toLowerCase() &&
    arrivalDataParamValue(visit) === searchParams.get("arrival").trim()
  );
}

export function useExplore(trip: TripReadyState): ExploreState {
  const tour = useMemo(() => trip.model.getTour(), [trip]);
  const [searchParams, setSearchParams] = useSearchParams();

  function navigate(visit: BaseVisitModel | undefined) {
    if (visit === undefined) {
      setSearchParams({});
      return;
    }

    setSearchParams({
      [visit.type]: visitNameParamValue(visit),
      arrival: arrivalDataParamValue(visit),
    });
  }

  const exploreState: ExploreState = {
    allVisits: [],
    breadcrumbs: [],
    navigate,
  };

  function processVisit(visit: VisitModel) {
    exploreState.allVisits.push(visit);

    if (isVisitMatch(visit, searchParams)) {
      exploreState.activeVisit = visit as any;
    }
  }

  const isMultiCountryTour = tour.getCountryVisits().length > 1;

  // Traverse the tour tree to add every visit to allVisits
  tour.getCountryVisits().forEach((countryVisit) => {
    if (isMultiCountryTour) {
      processVisit(countryVisit);
    }

    countryVisit.getRegionVisits().forEach((regionVisit) => {
      processVisit(regionVisit);

      regionVisit.getDestinationVisits().forEach((destinationVisit) => {
        processVisit(destinationVisit);
      });
    });

    countryVisit.getDestinationVisits().forEach((destinationVisit) => {
      processVisit(destinationVisit);
    });
  });

  exploreState.breadcrumbs.push({
    title: trip.model.getSummary().getName(),
    onClick: exploreState.activeVisit ? () => navigate(undefined) : undefined,
  });

  const { activeVisit: visit, breadcrumbs } = exploreState;

  if (visit) {
    switch (visit.type) {
      case "country":
        breadcrumbs.push({ title: exploreState.activeVisit.getName() });
        break;

      case "region":
        if (isMultiCountryTour) {
          breadcrumbs.push({
            title: visit.getParentVisit().getName(),
            onClick: () => navigate(visit.getParentVisit()),
          });
        }
        breadcrumbs.push({ title: visit.getName() });
        break;

      case "destination":
        let parents: VisitModel[] = [];

        if (visit.getParentVisit().type === "region") {
          const parent = visit.getParentVisit() as RegionVisitModel;
          parents.push(parent);
          parents.push(parent.getParentVisit());
        } else if (isMultiCountryTour) {
          parents.push(visit.getParentVisit());
        }

        parents.forEach((parent) => {
          breadcrumbs.push({
            title: parent.getName(),
            onClick: () => navigate(parent),
          });
        });

        breadcrumbs.push({ title: visit.getName() });
        break;
    }
  }

  return exploreState;
}

// let explore: ExploreState = useMemo(
//   () => ({
//     type: "tour",
//   }),
//   []
// );

// const arrivalDateParam = searchParams.get("arrival");
// if (searchParams.has("country")) {
//   explore = {
//     type: "country",
//     visits: getVisitMatches(
//       tour.getCountryVisits(),
//       searchParams.get("country"),
//       arrivalDateParam
//     ),
//   };
// } else if (searchParams.has("region")) {
//   const countryVisit = tour
//     .getCountryVisits()
//     .find((countryVisit) =>
//       getVisitMatches(
//         countryVisit.getRegionVisits(),
//         searchParams.get("region"),
//         arrivalDateParam
//       ).some((match) => match.active)
//     );

//   explore = {
//     type: "region",
//     visits: getVisitMatches(
//       countryVisit.getRegionVisits(),
//       searchParams.get("region"),
//       arrivalDateParam
//     ),
//   };
// } else if (searchParams.has("destination")) {
//   const regionVisit = tour
//     .getCountryVisits()
//     .flatMap((countryVisit) => countryVisit.getRegionVisits())
//     .find((regionVisit) =>
//       getVisitMatches(
//         regionVisit.getDestinationVisits(),
//         searchParams.get("destination"),
//         arrivalDateParam
//       ).some((match) => match.active)
//     );

//   if (regionVisit) {
//     explore = {
//       type: "destination",
//       visits: getVisitMatches(
//         regionVisit.getDestinationVisits(),
//         searchParams.get("destination"),
//         arrivalDateParam
//       ),
//     };
//   } else {
//     const countryVisit = tour
//       .getCountryVisits()
//       .find((countryVisit) =>
//         getVisitMatches(
//           countryVisit.getDestinationVisits(),
//           searchParams.get("destination"),
//           arrivalDateParam
//         ).some((match) => match.active)
//       );

//     explore = {
//       type: "destination",
//       visits: getVisitMatches(
//         countryVisit.getDestinationVisits(),
//         searchParams.get("destination"),
//         arrivalDateParam
//       ),
//     };
//   }
// }
