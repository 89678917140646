import { AnySourceData } from "mapbox-gl";
import { TripModel } from "../../../../../../modules/model/v1";

export interface AccomClusterFeatureProperties {
  type: "accom";
  name: string;
  checkInDate: string;
  checkOutDate: string;
  order: number;
}

export async function getAccomClusterSourceData(
  trip: TripModel
): Promise<AnySourceData> {
  const accomData: GeoJSON.FeatureCollection<
    GeoJSON.Geometry,
    AccomClusterFeatureProperties
  > = {
    type: "FeatureCollection",
    features: [],
  };

  trip.getAccommodations().forEach((accom, index) => {
    const { lon, lat } = accom.getLocation();
    accomData.features.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [lon, lat],
      },
      properties: {
        type: "accom",
        name: accom.getName(),
        checkInDate: accom.getCheckInDate().toISOString(),
        checkOutDate: accom.getCheckOutDate().toISOString(),
        order: index + 1,
      },
    });
  });

  return {
    type: "geojson",
    data: accomData,
    cluster: true,
    clusterMaxZoom: 8,
    clusterRadius: 15,
    clusterProperties: {
      minOrder: ["min", ["get", "order"]],
      maxOrder: ["max", ["get", "order"]],
    },
  };
}
