import { gql, useQuery } from "@apollo/client";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { Document } from "@contentful/rich-text-types";
import { Card, CardActions, CardContent, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import {
  TripCardQuery,
  TripCardQueryVariables,
} from "./__generated__/TripCardQuery";

const TRIP_CARD_QUERY = gql`
  query TripCardQuery($tripId: String!) {
    trip(id: $tripId) {
      sys {
        id
      }
      name
      notes {
        json
      }
    }
    firstEntry: journalEntryCollection(
      where: { trip: { sys: { id: $tripId } } }
      order: date_ASC
      limit: 1
    ) {
      items {
        sys {
          id
        }
        date
      }
    }
    lastEntry: journalEntryCollection(
      where: { trip: { sys: { id: $tripId } } }
      order: date_DESC
      limit: 1
    ) {
      items {
        sys {
          id
        }
        date
      }
    }
  }
`;

export function TripCard({
  tripId,
  actions,
}: {
  tripId: string;
  actions?: React.ReactElement;
}) {
  const { data, loading, error } = useQuery<
    TripCardQuery,
    TripCardQueryVariables
  >(TRIP_CARD_QUERY, { variables: { tripId } });

  if (error) {
    throw error;
  }

  if (loading) {
    return <span>Loading...</span>;
  }

  const { trip } = data!;

  if (!trip) {
    throw new Error("Trip not found");
  }

  const startDate: string | undefined = data!.firstEntry?.items?.[0]?.date;
  const endDate = data!.lastEntry?.items?.[0]?.date;

  let tripDuration: string | undefined;

  if (startDate && endDate) {
    const formatted = [startDate, endDate].map((date) =>
      DateTime.fromISO(startDate).toFormat("MMM yyyy")
    );
    tripDuration =
      formatted[0] === formatted[1] ? formatted[0] : formatted.join(" - ");
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          {trip.name}
        </Typography>
        {tripDuration && (
          <Typography color="textSecondary">{tripDuration}</Typography>
        )}
        {trip!.notes && (
          <Typography
            variant="body2"
            component="p"
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(trip.notes.json as any as Document),
            }}
          />
        )}
      </CardContent>
      {actions && <CardActions>{actions}</CardActions>}
    </Card>
  );
}
