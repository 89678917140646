import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { createTheme, Theme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Map } from "mapbox-gl";
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import InstagramEmbed from "./components/InstagramEmbed";
import { ContentfulModelProvider } from "./modules/contentful/v1";
import { AccountModel } from "./modules/model/v1";
import HomeRoute from "./routes/home/HomeRoute";
import TripV1Route from "./routes/trip/v1/TripV1Route";
import TripV2Route from "./routes/trip/v2/TripV2Route";

const useStyles = makeStyles((theme) => ({
  map: {
    position: "fixed",
    width: "100%",
    height: "66vh",
    [theme.breakpoints.up("sm")]: {
      height: "100vh",
      width: "65vw",
    },
  },
  explorer: {
    position: "absolute",
    boxSizing: "border-box",
    transition: "top 300ms ease-in-out",
    top: "66vh",
    width: "100vw",
    [theme.breakpoints.up("sm")]: {
      minHeight: "100vh",
      top: 0,
      right: 0,
      width: "35vw",
    },
  },
}));

const mapCenter = {
  center: [13.316192577577112, 47.92650310387842] as [number, number],
  zoom: 2,
};

declare global {
  interface Window {
    _map: Map;
  }
}

export interface MapUtils {
  map: Map | null;
  resetMapPosition: () => void;
}

interface IconSpec {
  url: string;
  origin: string;
  size: [number, number];
}

/**
 * Using icons8
 * 1. Use the "Material Filled" collection
 * 2. Use "Recolor" to change stroke color
 * 3. Click Download button, select Base64, 16x16 and copy data URL
 */
const Icons: { [id: string]: IconSpec | undefined } = {
  "plane-black": {
    url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAz0lEQVRIieWVuw4BQRSGv1BIJN5A4R00dNrVeAWV1mNQqWmobeFJtGI7iU30tpaMYkecZe1mx5xE4k+mOpn/m8u5wD/oCBhgpAWYWcAZaGkAehZggIUGoAZcLOAGdDUgS5632AN134BAAAwwdTE5AQegmRNrAFcBSIB2VcDGbl5/iG/J3mJXFTB+MXBdMemTvqnjCfCoGSBNQVVJwMCTZwxM8gJlnxzy5SeXpWkizJ3StEhDsqd3KrQirYS591ah3uz6KLfrOcoDJ0J5ZP627kfKYsAIyiRLAAAAAElFTkSuQmCC",
    origin: "https://icons8.com/icon/90465/plane",
    size: [24, 24],
  },
  "train-black": {
    url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAApUlEQVRIie2TwQ2DMAxFX1HVRRij6gDtYGUPDlSsVeghXYJeMEIgSGITCaR+6d/i953Ygb8MegAN0HncAHdNQAhc/I4B50AVARdXfa0X7hRwsfOF1Aa4+LUW8N0gwI2Bp0lAt5YeoYF79h2I1KzBTAkK1vEDlmaw1bD390RX4JYyIEO/wgB8sP/kdtrRWKWluxDGBSjQ3aQFnj0jSFJoOpd8TZPrByIDgAKrCZJPAAAAAElFTkSuQmCC",
    origin: "https://icons8.com/icon/90558/train",
    size: [24, 24],
  },
  "car-black": {
    url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA1klEQVRIie3UvWoCURAF4M8Qf4oEY5lAxMqk1N59keQhzTukCljY5AW20kZMSiNo4Yo3y7qLsEt+8MDAHWbunGHu3MMZfxWbxApxUXEjasG5hyc84h63uEEDdVxl3I/xgU/MEv8dYyzCxChJ2pRkcwxDgkkqIcJDToEWOgUkr+GIvnCZMbpjD1kUhxWaxxLLIIBa5Vv0fwjWFdRehQTTVDBCP+fyfk3z8BY6I+V+tBkGfJeKLp4dpOIObTTt/sh1RpcxlnZyMXeQihcpqTgVv0dNz/h5bAHPDlmBp3hcSgAAAABJRU5ErkJggg==",
    origin: "https://icons8.com/icon/90516/jeep",
    size: [24, 24],
  },
};

const loadImages = (map: Map) =>
  Promise.all(
    Object.entries(Icons).map(
      ([id, { url }]) =>
        new Promise<void>((resolve, reject) =>
          map.loadImage(url, (error, image) => {
            if (error) {
              return reject(error);
            }

            map.addImage(id, image);
            resolve();
          })
        )
    )
  );

const useMapContext: () => MapUtils = () => {
  // const mapPromise = useRef<Promise<void>>();
  const [map, setMap] = useState<Map>();

  const context: MapUtils = {
    map,
    resetMapPosition() {
      if (map) {
        map.flyTo(mapCenter);
      }
    },
  };

  useEffect(() => {
    if (document.getElementById("map")) {
      window._map = new Map({
        container: "map",
        style: "mapbox://styles/mapbox/outdoors-v12",
        ...mapCenter,
      });

      loadImages(window._map)
        .then(
          () =>
            new Promise((resolve) =>
              window._map.on("load", () => resolve(window._map))
            )
        )
        .then(() => setMap(window._map));
    }
  }, []);

  return context;
};

type AccountModelState =
  | { state: "loading" }
  | { state: "error"; error: Error }
  | { state: "ready"; account: AccountModel };

function useAccountModel(): AccountModelState {
  const [accountState, setAccountState] = useState<AccountModelState>({
    state: "loading",
  });

  useEffect(() => {
    new ContentfulModelProvider()
      .getAccount()
      .then((account) => setAccountState({ state: "ready", account }))
      .catch((error) => setAccountState({ state: "error", error }));
  }, []);

  return accountState;
}

const MapContext = React.createContext<MapUtils>(null);

const AccountContext = React.createContext<AccountModel>(null);

export function useAccount(): AccountModel {
  return useContext(AccountContext);
}

export const useMap: () => MapUtils = () => useContext(MapContext);

function App() {
  const styles = useStyles();

  const mapContext = useMapContext();

  const accountState = useAccountModel();

  console.log({ map: mapContext.map, account: accountState.state });

  if (accountState.state === "error") {
    throw accountState.error;
  }

  const loading = !mapContext.map || accountState.state === "loading";

  // const [showTutorial, setShowTutorial] = useState(false);

  // useEffect(() => {
  //   if (loading) {
  //     return;
  //   }

  //   if (!localStorage.getItem("tutorial")) {
  //     setShowTutorial(true);
  //     localStorage.setItem("tutorial", "true");
  //   }
  // }, [loading]);

  return (
    <>
      <Box className={styles.map} id="map" data-joyride="map" />
      <Box>
        {loading && (
          <Box
            position="fixed"
            width="100vw"
            height="100vh"
            bgcolor="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography>Loading...</Typography>
          </Box>
        )}
        {!loading && accountState.state === "ready" && (
          <Box bgcolor="white" p={2} className={styles.explorer}>
            <MapContext.Provider value={mapContext}>
              <AccountContext.Provider value={accountState.account}>
                <Outlet />
              </AccountContext.Provider>
            </MapContext.Provider>
          </Box>
        )}
      </Box>
      {/* {showTutorial && <Tutorial />} */}
    </>
  );
}

function TestRoute() {
  const instagramTest = (
    <>
      <InstagramEmbed url="https://www.instagram.com/p/BydZ2KYHRS9/" />
      <InstagramEmbed url="https://www.instagram.com/p/BydO0YMgbhk/" />
    </>
  );

  return <>{instagramTest}</>;
}

const client = new ApolloClient({
  uri: "https://graphql.contentful.com/content/v1/spaces/ejd4tt2t7nui/environments/master",
  headers: {
    Authorization: "Bearer qW8hjqyMwr3CjKAz47iNnmaL9AHAcsKi-klJs9Gk2bw",
  },
  connectToDevTools: true,
  cache: new InMemoryCache({
    typePolicies: {
      Accomodation: {
        keyFields: ["sys", ["id"]],
      },
      Activity: {
        keyFields: ["sys", ["id"]],
      },
      Location: {
        keyFields: ["lat", "lon"],
      },
      JournalEntry: {
        keyFields: ["sys", ["id"]],
      },
      Trip: {
        keyFields: ["sys", ["id"]],
      },
    },
  }),
});

// Doing this here avoids having to passing generic parameters to makeStyles
// See https://mui.com/material-ui/migration/v5-style-changes/#%E2%9C%85-add-module-augmentation-for-defaulttheme-typescript
declare module "@mui/styles" {
  interface DefaultTheme extends Theme {}
}

export default function AppContainer() {
  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <ThemeProvider theme={createTheme()}>
          <Routes>
            <Route path="/" element={<App />}>
              <Route path="trips/v1/:tripSlug/*" element={<TripV1Route />} />
              <Route path="trips/v2/:tripSlug/*" element={<TripV2Route />} />
              <Route index element={<HomeRoute />} />
            </Route>
            <Route path="/test" element={<TestRoute />} />
          </Routes>
        </ThemeProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
}
