import { setupCache } from "axios-cache-adapter";
import { Asset, createClient } from "contentful";
import { Memoize } from "typescript-memoize";
import {
  IAccomodation,
  IActivity,
  IJournalEntry,
  IJourney,
  IJourneyLeg,
  ILocation,
  ITrip,
} from "../../__generated__/contentful";
import { cacheGet, forageStore, isCachingEnabled } from "../cache";
import {
  AccommodationModel,
  AccountModel,
  ActivityModel,
  ActivityType,
  Coordinates,
  DestinationModel,
  HikeActivityModel,
  JourneyLegModel,
  JourneyLegType,
  JourneyModel,
  ModelProvider,
  TourModel,
  TripModel,
  TripSummaryModel,
} from "../model/v1";
import { ContentfulTour } from "./v1/tour";
import { gpxToGeoJSON } from "../geometry";

const spaceId = "ejd4tt2t7nui";
const apiKey = "QslGrl68VGVVuqmzuGYCAXZ1PD9sGkCfhuVvvTZGhWg";
// const environmentId = "master";

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000, // 15min
  store: forageStore,
  exclude: {
    query: false,
  },
});

const client = createClient({
  space: spaceId,
  accessToken: apiKey,
  adapter: isCachingEnabled() ? cache.adapter : undefined,
});

export const CONTENT_TYPE_ACCOMMODATION = "accomodation" as const;
export const CONTENT_TYPE_ACTIVITY = "activity" as const;
export const CONTENT_TYPE_JOURNAL_ENTRY = "journalEntry" as const;
export const CONTENT_TYPE_JOURNEY = "journey" as const;
export const CONTENT_TYPE_LOCATION = "location" as const;

export interface ContentfulData {
  tripEntry: ITrip;
  linkedEntries: TripLinkedEntries;
  assets: Record<string, FetchedAsset>; // keyed by asset ID
}

class ContentfulTrip implements TripModel {
  constructor(private data: ContentfulData) {}

  getSummary(): TripSummaryModel {
    return new ContentfulTripSummary(this.data.tripEntry);
  }

  @Memoize()
  getTour(): TourModel {
    return new ContentfulTour(this.getAccommodations(), this.data);
  }

  @Memoize()
  getDestinations(): DestinationModel[] {
    const locationEntries = Object.values(
      this.data.linkedEntries[CONTENT_TYPE_LOCATION]
    );

    return locationEntries.map(
      (entry) => new ContentfulDestination(entry, this.data)
    );
  }

  @Memoize()
  getJourneys(): JourneyModel[] {
    const journeyEntries = Object.values(
      this.data.linkedEntries[CONTENT_TYPE_JOURNEY]
    );
    const journeyModels = journeyEntries.map(
      (entry) => new ContentfulJourney(entry, this.data)
    );
    journeyModels.sort(
      (a, b) =>
        a.getLegs()[0].getStartDate().getTime() -
        b.getLegs()[0].getStartDate().getTime()
    );
    return journeyModels;
  }

  @Memoize()
  getAccommodations(): AccommodationModel[] {
    const accomEntries = Object.values(
      this.data.linkedEntries[CONTENT_TYPE_ACCOMMODATION]
    );
    const accomModels = accomEntries.map(
      (entry) => new ContentfulAccommodation(entry, this.data)
    );
    accomModels.sort(
      (a, b) => a.getCheckInDate().getTime() - b.getCheckInDate().getTime()
    );
    return accomModels;
  }

  @Memoize()
  getActivities(): ActivityModel[] {
    const activityEntries = Object.values(
      this.data.linkedEntries[CONTENT_TYPE_ACTIVITY]
    );

    return activityEntries.map((entry) =>
      ContentfulActivity.create(entry, this.data)
    );
  }
}

class ContentfulDestination implements DestinationModel {
  constructor(private entry: ILocation, private data: ContentfulData) {}

  // getVisits(): DestinationVisitModel[] {
  //   const visits: DestinationVisitModel[] = [];

  //   const accoms = Object.values(
  //     this.data.linkedEntries[CONTENT_TYPE_ACCOMMODATION]
  //   );
  //   let visitArrival: string | null = null;

  //   accoms.forEach((accom, i) => {
  //     if (accom.fields.locationRef.sys.id === this.entry.sys.id) {
  //       if (visitArrival === null) {
  //         visitArrival = accom.fields.checkIn;
  //       }
  //     } else if (visitArrival !== null) {
  //       visits.push(
  //         new ContentfulDestinationVisit(
  //           this,
  //           new Date(visitArrival),
  //           new Date(accoms[i - 1].fields.checkOut)
  //         )
  //       );
  //       visitArrival = null;
  //     }
  //   });

  //   return visits;
  // }

  getName(): string {
    return this.entry.fields.name?.trim();
  }
  getRegion(): string | undefined {
    return this.entry.fields.region?.trim();
  }
  getCountry(): string {
    return this.entry.fields.country?.trim();
  }
}

export class ContentfulActivity implements ActivityModel {
  constructor(protected entry: IActivity, protected data: ContentfulData) {}

  public static create(
    entry: IActivity,
    data: ContentfulData
  ): ContentfulActivity {
    switch (entry.fields.type) {
      case "hike":
        return new ContentfulHikeActivity(entry, data);
      default:
        return new ContentfulActivity(entry, data);
    }
  }

  getId(): string {
    return this.entry.sys.id;
  }

  getTitle(): string {
    return this.entry.fields.title;
  }

  getLocation(): Coordinates {
    return this.entry.fields.location;
  }
  getType(): ActivityType {
    return this.entry.fields.type as ActivityType;
  }
}

export class ContentfulHikeActivity
  extends ContentfulActivity
  implements HikeActivityModel
{
  getType(): ActivityType.HIKE {
    return ActivityType.HIKE;
  }

  getPathGeometry(): GeoJSON.Geometry {
    const { data } = this.entry.fields;

    if (!data) {
      throw new Error(`Hike activity "${this.entry.fields.title}" has no data`);
    }

    return gpxToGeoJSON(
      this.entry.sys.id,
      this.data.assets[this.entry.fields.data!.sys.id].data
    );
  }
}

export class ContentfulAccommodation implements AccommodationModel {
  constructor(private entry: IAccomodation, private data: ContentfulData) {}

  getName(): string {
    return this.entry.fields.name;
  }

  getLocation(): Coordinates {
    return this.entry.fields.location;
  }
  getCheckInDate(): Date {
    return new Date(this.entry.fields.checkIn);
  }
  getCheckOutDate(): Date {
    return new Date(this.entry.fields.checkOut);
  }

  getDestination(): DestinationModel {
    return new ContentfulDestination(
      this.data.linkedEntries[CONTENT_TYPE_LOCATION][
        this.entry.fields.locationRef.sys.id
      ],
      this.data
    );
  }
}

class ContentfulJourney implements JourneyModel {
  constructor(private journeyEntry: IJourney, private data: ContentfulData) {}

  getTitle(): string {
    return this.journeyEntry.fields.title;
  }

  getLegs(): JourneyLegModel[] {
    return this.journeyEntry.fields.legs.map(
      (legEntry) => new ContentfulJourneyLeg(legEntry, this.data)
    );
  }
}

class ContentfulJourneyLeg implements JourneyLegModel {
  constructor(
    private journeyLegEntry: IJourneyLeg,
    private data: ContentfulData
  ) {}

  getTitle(): string {
    return this.journeyLegEntry.fields.title;
  }
  getType(): JourneyLegType {
    return this.journeyLegEntry.fields.type as JourneyLegType;
  }

  getStartDate(): Date {
    return new Date(this.journeyLegEntry.fields.startDate);
  }

  getOriginLocation(): Coordinates {
    const { originEntry, origin } = this.journeyLegEntry.fields;

    if (originEntry) {
      return originEntry.fields.location;
    }

    return origin;
  }

  fitOriginToMap(): boolean {
    const { fitOriginToMap } = this.journeyLegEntry.fields;
    return fitOriginToMap === undefined ? true : fitOriginToMap;
  }

  getEndDate(): Date {
    return new Date(this.journeyLegEntry.fields.endDate);
  }

  getDestinationLocation(): Coordinates {
    const { destinationEntry, destination } = this.journeyLegEntry.fields;

    if (destinationEntry) {
      return destinationEntry.fields.location;
    }

    return destination;
  }

  fitDestinationToMap(): boolean {
    const { fitDestinationToMap } = this.journeyLegEntry.fields;
    return fitDestinationToMap === undefined ? true : fitDestinationToMap;
  }

  getWaypointLocations(): Coordinates[] {
    const { waypoints } = this.journeyLegEntry.fields;

    if (!waypoints) {
      return [];
    }

    return waypoints.map((waypointEntry) => {
      try {
        return waypointEntry.fields.location;
      } catch (error) {
        const message = "Failed to extract location from waypoint";
        console.error(message, waypointEntry);
        throw new Error(message);
      }
    });
  }

  getPathGeometry(): GeoJSON.Geometry | undefined {
    const pathAsset = this.journeyLegEntry.fields.path;

    if (pathAsset === undefined) {
      return undefined;
    }

    const fetchedAsset: FetchedAsset = this.data.assets[pathAsset.sys.id];

    let geometry: GeoJSON.Geometry;

    switch (fetchedAsset.asset.fields.file.contentType) {
      case "application/json":
        geometry = JSON.parse(fetchedAsset.data);
        break;

      case "application/xml":
        geometry = gpxToGeoJSON(fetchedAsset.asset.sys.id, fetchedAsset.data);
        break;

      default:
        throw new Error(
          `Unsupported path asset type ${fetchedAsset.asset.fields.file.contentType}`
        );
    }

    return geometry;
  }
}

interface TripLinkedEntries {
  [CONTENT_TYPE_ACCOMMODATION]: Record<string, IAccomodation>;
  [CONTENT_TYPE_ACTIVITY]: Record<string, IActivity>;
  [CONTENT_TYPE_JOURNAL_ENTRY]: Record<string, IJournalEntry>;
  [CONTENT_TYPE_JOURNEY]: Record<string, IJourney>;
  [CONTENT_TYPE_LOCATION]: Record<string, ILocation>;
}

type FetchedAsset = {
  asset: Asset;
  data: string;
};

export class ContentfulModelProvider implements ModelProvider {
  @Memoize()
  async getAccount(): Promise<AccountModel> {
    const trips = (
      await client.getEntries({
        content_type: "trip",
      })
    ).items as ITrip[];

    return new ContentfulAccount(trips);
  }

  @Memoize()
  async getTrip(id: string): Promise<TripModel> {
    const tripEntry: ITrip = (await client.getEntry(id)) as any;

    // Using /entries/links_to_entry={tripId}
    // Set(5) {'journey', 'journalEntry', 'activity', 'accomodation', 'location'}
    const linkedEntryCollection: {
      items: Array<
        IAccomodation | IActivity | IJournalEntry | IJourney | ILocation
      >;
      includes?: {
        Entry?: any;
        Asset?: Asset[];
      };
    } = await cacheGet<any>(`contentfulEntries.${id}`, { disable: true }, () =>
      client.getEntries({
        links_to_entry: id,
        // Without this we don't get linked entries such as Waypoints on JourneyLegs
        include: 2,
        limit: 999,
      })
    );

    const linkedEntries: TripLinkedEntries = {
      [CONTENT_TYPE_ACCOMMODATION]: {},
      [CONTENT_TYPE_ACTIVITY]: {},
      [CONTENT_TYPE_JOURNAL_ENTRY]: {},
      [CONTENT_TYPE_JOURNEY]: {},
      [CONTENT_TYPE_LOCATION]: {},
    };

    linkedEntryCollection.items.forEach((item) => {
      linkedEntries[item.sys.contentType.sys.id][item.sys.id] = item;
    });

    const assets: {
      [id: string]: FetchedAsset;
    } = {};

    if (linkedEntryCollection.includes?.Asset?.length > 0) {
      await Promise.all(
        linkedEntryCollection.includes.Asset.map(async (asset) => {
          const data = await cacheGet(`asset.${asset.sys.id}`, () =>
            fetch(asset.fields.file.url).then((res) => res.text())
          );

          const fetchedAsset: FetchedAsset = {
            asset,
            data,
          };

          assets[asset.sys.id] = fetchedAsset;
        })
      );
    }

    return new ContentfulTrip({ tripEntry, linkedEntries, assets });
  }
}

export class ContentfulAccount implements AccountModel {
  constructor(private trips: ITrip[]) {}

  getTripSummaries(): TripSummaryModel[] {
    return this.trips.map((trip) => new ContentfulTripSummary(trip));
  }
}

export class ContentfulTripSummary implements TripSummaryModel {
  constructor(private trip: ITrip) {}

  getId(): string {
    return this.trip.sys.id;
  }

  getName(): string {
    return this.trip.fields.name;
  }

  getVersion(): "v1" | "v2" {
    return this.trip.fields.schemaVersion;
  }
}
