import { IJournalEntry } from "../../../__generated__/contentful";
import {
  ActivityModel,
  InstragramPostModel,
  JournalEntryModel,
} from "../../model/v1";
import { ContentfulActivity, ContentfulData } from "../v1";

export class ContentfulJournalEntry implements JournalEntryModel {
  constructor(private entry: IJournalEntry, private data: ContentfulData) {}

  getDate(): Date {
    return new Date(this.entry.fields.date);
  }

  getInstagramPosts(): InstragramPostModel[] {
    return (this.entry.fields.instagramPosts || []).map((url) => ({
      getUrl: () => url,
    }));
  }

  getActivities(): ActivityModel[] {
    return this.entry.fields.activities.map((activity) =>
      ContentfulActivity.create(activity, this.data)
    );
  }
}
