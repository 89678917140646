import { Layers, LayerSpec, Theme } from ".";
import { Sources } from "../sources";

export function getJourneyLayers(theme: Theme): LayerSpec[] {
  const journeyLineLayer: LayerSpec = [
    {
      id: Layers.JourneyLine,
      type: "line",
      source: Sources.Journeys,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": theme.lineColor,
        "line-width": 4,
        "line-dasharray": [
          "match",
          ["get", "type"],
          "flight",
          ["literal", [2, 4]],
          ["literal", [1, 0]],
        ],
        "line-opacity": [
          // Interpolate line opacity based on zoom level
          "interpolate",
          ["linear"],
          ["zoom"],
          // case: zoom is <=2
          2,
          ["match", ["get", "type"], "flight", 0.33, 1],
          // case: zoom is >=10
          10,
          [
            "match",
            ["get", "type"],
            // flight paths
            "flight",
            // longer flights get less opacity
            [
              "interpolate",
              ["linear"],
              ["get", "length"],
              // paths of 0 length get 0.25 opacity
              0,
              0.33,
              // paths of 5_000_000 length get 0.05 opacity
              5_000_000,
              0.05,
            ],
            // default to 1
            1,
          ],
        ],
      },
    },
    // For Frank style use
    // "road-label-simple",
    "contour-label",
  ];

  // const journeyIconsLayer: LayerSpec = {
  //   id: Layers.JourneyIcons,
  //   source: Sources.Journeys,
  //   type: "symbol",
  //   filter: ["==", ["get", "type"], "flight"],
  //   layout: {
  //     "icon-image": [
  //       "match",
  //       ["get", "type"],
  //       "flight",
  //       "plane-black",
  //       "car",
  //       "car-black",
  //       "foot",
  //       "pitch",
  //       "train",
  //       "train-black",
  //       "arrow",
  //     ],
  //     "icon-allow-overlap": false,
  //     "symbol-placement": "line",
  //     "symbol-spacing": 125,
  //     // "icon-rotate": ["match", ["get", "type"], "car", 180, "flight", 90, 0],
  //   },
  // };

  return [journeyLineLayer];
}
