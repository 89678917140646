export interface AccountModel {
  getTripSummaries(): TripSummaryModel[];
}

export interface TripSummaryModel {
  getId(): string;
  getName(): string;
  getVersion(): "v1" | "v2";
}

export interface TripModel {
  getSummary(): TripSummaryModel;
  getJourneys(): JourneyModel[];
  getAccommodations(): AccommodationModel[];
  getActivities(): ActivityModel[];
  getDestinations(): DestinationModel[];
  getTour(): TourModel;
}

export interface DestinationModel {
  getName(): string;
  getRegion(): string | undefined;
  getCountry(): string;
  // getVisits(): DestinationVisitModel[];
}

export interface TourModel {
  getCountryVisits(): CountryVisitModel[];
}

export interface BaseVisitModel {
  type: "destination" | "region" | "country";
  getName(): string;
  getArrivalDate(): Date;
  getDepartureDate(): Date;
  getAccommodations(): AccommodationModel[];
  getActivities(): ActivityModel[];
}

export interface CountryVisitModel extends BaseVisitModel {
  type: "country";
  getRegionVisits(): RegionVisitModel[];
  getDestinationVisits(): DestinationVisitModel[];
}

export interface RegionVisitModel extends BaseVisitModel {
  type: "region";
  getParentVisit(): CountryVisitModel;
  getDestinationVisits(): DestinationVisitModel[];
}

export interface DestinationVisitModel extends BaseVisitModel {
  type: "destination";
  getParentVisit(): CountryVisitModel | RegionVisitModel;
  getDestination(): DestinationModel;
  getJournalEntries(): JournalEntryModel[];
}

export type VisitModel =
  | CountryVisitModel
  | RegionVisitModel
  | DestinationVisitModel;

export interface JourneyModel {
  getTitle(): string;
  getLegs(): JourneyLegModel[];
}

export interface JourneyLegModel {
  getTitle(): string;
  getType(): JourneyLegType;
  getStartDate(): Date;
  getOriginLocation(): Coordinates;
  fitOriginToMap(): boolean;
  getEndDate(): Date;
  getDestinationLocation(): Coordinates;
  fitDestinationToMap(): boolean;
  getWaypointLocations(): Coordinates[];
  getPathGeometry(): GeoJSON.Geometry | undefined;
}

export enum JourneyLegType {
  FLIGHT = "flight",
  FERRY = "ferry",
  CAR = "car",
  TRAIN = "train",
  FOOT = "foot",
}

export interface ModelProvider {
  getAccount(): Promise<AccountModel>;
  getTrip(id: string): Promise<TripModel>;
}

export interface Coordinates {
  lat: number;
  lon: number;
}

export interface AccommodationModel {
  getName(): string;
  getLocation(): Coordinates;
  getCheckInDate(): Date;
  getCheckOutDate(): Date;
  getDestination(): DestinationModel;
}

export enum ActivityType {
  SIGHT = "sight",
  HIKE = "hike",
  FOOD_DRINK = "food+drink",
}

export interface ActivityModel {
  getId(): string;
  getTitle(): string;
  getLocation(): Coordinates;
  getType(): ActivityType;
}

export interface SightActivityModel extends ActivityModel {
  getType(): ActivityType.SIGHT;
}

export interface HikeActivityModel extends ActivityModel {
  getType(): ActivityType.HIKE;
  getPathGeometry(): GeoJSON.Geometry;
}

export interface JournalEntryModel {
  getDate(): Date;
  getInstagramPosts(): InstragramPostModel[];
  getActivities(): ActivityModel[];
}

export interface InstragramPostModel {
  getUrl(): string;
}
