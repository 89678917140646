import { AnyLayer } from "mapbox-gl";

export const Layers = {
  JourneyLine: "journeyLine",
  JourneyIcons: "journeyIcons",
  AccomClusterCircles: "accomClusters",
  AccomClusterLabels: "accomClusterLabels",
  AccomCircles: "accomCircles",
  AccomLabels: "accomLabels",
  ActivityLines: "activityLines",
  ActivityIcons: "activityIcons",
  ActivityLabels: "activityLabels",
};

export type LayerSpec = AnyLayer | [AnyLayer, string];

// https://www.canva.com/colors/color-meanings/

const Pallettes = {
  // https://coolors.co/palette/ef476f-ffd166-06d6a0-118ab2-073b4c
  colorful: {
    pink: "#EF476F",
    yellow: "#FFD166",
    green: "#06D6A0",
    lightBlue: "#118AB2",
    darkBlue: "#073B4C",
  },
  monoGreen: {
    green: "#354f52",
  },
};

export interface Theme {
  lineColor: string;
  accomColor: string;
}

// https://coolors.co/palette/ef476f-ffd166-06d6a0-118ab2-073b4c
export const ColorfulTheme: Theme = {
  lineColor: Pallettes.colorful.green,
  accomColor: Pallettes.colorful.darkBlue,
};

export const MonoGreenTheme: Theme = {
  lineColor: Pallettes.monoGreen.green,
  accomColor: Pallettes.monoGreen.green,
};

export const SamsForestGreenTheme: Theme = {
  lineColor: "#228B22",
  accomColor: "#228B22",
};

export const TangerineTheme: Theme = {
  lineColor: "#F28500",
  accomColor: "#F28500",
};
