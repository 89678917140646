import { gql, useQuery } from "@apollo/client";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { Document } from "@contentful/rich-text-types";
import { Badge, Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { TabProps } from "@mui/material/Tab";
import WalkIcon from "@mui/icons-material/DirectionsWalk";
import HouseIcon from "@mui/icons-material/House";
import InstagramIcon from "@mui/icons-material/Instagram";
import NotesIcon from "@mui/icons-material/Notes";
import NextIcon from "@mui/icons-material/SkipNext";
import PreviousIcon from "@mui/icons-material/SkipPrevious";
import createDebug from "debug";
import { DateTime } from "luxon";
import { AnySourceData, LngLat, LngLatBounds } from "mapbox-gl";
import React, { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InstagramEmbed from "../../../components/InstagramEmbed";
import { useMap } from "../../../App";
import { ActivityCard } from "./ActivityCard";
import {
  ExplorerHeaderQuery,
  ExplorerHeaderQueryVariables,
} from "./__generated__/ExplorerHeaderQuery";
import {
  ExplorerQuery,
  ExplorerQueryVariables,
  ExplorerQuery_journalEntry,
} from "./__generated__/ExplorerQuery";

const log = createDebug("mbf:Explorer");

interface DateNavigationProps {
  date: Date;
  onNext: () => void;
  onPrevious: () => void;
}

const DateNavigation: React.ComponentType<DateNavigationProps> = ({
  date,
  onNext,
  onPrevious,
}: DateNavigationProps) => {
  // const { content, explorer } = useStore();

  let previousEnabled = true;
  // try {
  //   explorer.getTrip().getJournalEntry(explorer.getDate(-1));
  // } catch {
  //   previousEnabled = false;
  // }

  let nextEnabled = true;
  // try {
  //   explorer.getTrip().getJournalEntry(explorer.getDate(1));
  // } catch {
  //   nextEnabled = false;
  // }

  return (
    <div data-joyride="date-nav">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button onClick={onPrevious} disabled={!previousEnabled}>
          <PreviousIcon />
        </Button>
        <div>{date ? new Date(date).toDateString() : null}</div>
        <Button onClick={onNext} disabled={!nextEnabled}>
          <NextIcon />
        </Button>
      </Box>
    </div>
  );
};

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box py={1}>{children}</Box>
    </Typography>
  );
}

const tabStyles = {
  root: {
    minWidth: "auto",
    maxWidth: "auto",
    flex: 1,
  },
};

const StyledTab: React.ComponentType<TabProps> = withStyles(tabStyles)(
  function StyledTab({ classes, ...props }: WithStyles<typeof tabStyles>) {
    return <Tab classes={classes} {...props} />;
  }
);

const TabHeader = ({ children }: { children: string }) => (
  <Box pt={2} pb={1}>
    <Typography variant="h5">{children}</Typography>
  </Box>
);

type TabNames = "instagram" | "accommodation" | "activities" | "notes";
const tabs: TabNames[] = ["instagram", "activities", "notes", "accommodation"];

interface TabElements {
  icon: ReactElement;
  badge?: (data: ExplorerQuery_journalEntry) => number | string;
  content: (
    tabValue: number,
    entry: ExplorerQuery_journalEntry
  ) => ReactElement;
}

const accommodationTab: TabElements = {
  icon: <HouseIcon />,
  content: (tabValue, entry) => (
    <TabPanel value={tabValue} index={tabs.indexOf("accommodation")}>
      <TabHeader>Accommodation</TabHeader>
      {entry.accommodation.name}
    </TabPanel>
  ),
};

const activitiesTab: TabElements = {
  icon: <WalkIcon />,
  badge: (entry) => entry.activitiesCollection.total,
  content: (tabValue, entry) => (
    <TabPanel value={tabValue} index={tabs.indexOf("activities")}>
      <Box pb={1}>
        <TabHeader>Activities</TabHeader>
        {entry.activitiesCollection.items.map((activity) => (
          <ActivityCard key={activity.sys.id} activity={activity} />
        ))}
      </Box>
    </TabPanel>
  ),
};

const instagramTab: TabElements = {
  icon: <InstagramIcon />,
  badge: (entry) => (entry.instagramPosts ? entry.instagramPosts.length : 0),
  content: (tabValue, entry) => (
    <TabPanel value={tabValue} index={tabs.indexOf("instagram")}>
      <TabHeader>Posts</TabHeader>
      {(entry.instagramPosts || []).map((url) => (
        <InstagramEmbed key={url} url={url} />
      ))}
    </TabPanel>
  ),
};

const notesTab: TabElements = {
  icon: <NotesIcon />,
  badge: (entry) => (entry.text ? " " : 0),
  content: (tabValue, entry) => (
    <TabPanel value={tabValue} index={tabs.indexOf("notes")}>
      <TabHeader>Notes</TabHeader>
      {entry.text && (
        <div
          dangerouslySetInnerHTML={{
            __html: documentToHtmlString(entry.text.json as any as Document),
          }}
        />
      )}
    </TabPanel>
  ),
};

const EXPLORER_HEADER_QUERY = gql`
  query ExplorerHeaderQuery($entryId: String!) {
    journalEntry(id: $entryId) {
      sys {
        id
      }
      locationsCollection {
        items {
          sys {
            id
          }
          name
          region
        }
      }
    }
  }
`;

function useHeader(entryId: string | undefined): string {
  const { data, loading, error } = useQuery<
    ExplorerHeaderQuery,
    ExplorerHeaderQueryVariables
  >(EXPLORER_HEADER_QUERY, { variables: { entryId } });

  if (error) {
    throw error;
  }

  if (loading) {
    return null;
  }

  const locations = data.journalEntry.locationsCollection.items;

  const headerLocation = locations.length === 2 ? locations[1] : locations[0];

  return [headerLocation.name, headerLocation.region]
    .filter(Boolean)
    .join(", ");
}

interface JournalEntryFragment {
  sys: {
    id: string;
  };
  date: string;
}

interface ExplorerProps {
  journal: JournalEntryFragment[];
  navigate: (entryId: string) => void;
}

const EXPLORER_QUERY = gql`
  query ExplorerQuery($entryId: String!) {
    journalEntry(id: $entryId) {
      sys {
        id
      }
      date
      text {
        json
      }
      instagramPosts
      accommodation {
        sys {
          id
        }
        name
        location {
          lat
          lon
        }
      }
      activitiesCollection {
        total
        items {
          sys {
            id
          }
          title
          type
          description {
            json
          }
          location {
            lat
            lon
          }
          data {
            url
          }
        }
      }
    }
  }
`;

function useMapBounds(entry: ExplorerQuery_journalEntry | undefined) {
  const { map } = useMap();

  useEffect(() => {
    if (!entry) {
      return;
    }

    let bounds = new LngLatBounds(
      entry.accommodation.location,
      entry.accommodation.location
    );

    if (entry.activitiesCollection.total > 0) {
      entry.activitiesCollection.items.forEach(
        ({ location: activityLocation }) =>
          bounds.extend(new LngLat(activityLocation.lon, activityLocation.lat))
      );
    }

    map.fitBounds(bounds, {
      padding: 96,
      maxZoom: 12,
    });
  }, [entry, map]);
}

function useActivities(entry: ExplorerQuery_journalEntry | undefined) {
  const { map } = useMap();

  useEffect(() => {
    if (!entry || !entry.activitiesCollection) {
      return;
    }

    const sources: { [id: string]: AnySourceData } = {};
    const layersToAdd: mapboxgl.AnyLayer[] = [];
    const layersToRemove: mapboxgl.AnyLayer[] = [];

    async function populateFeatures() {
      for (const activity of entry.activitiesCollection.items || []) {
        const { data, title } = activity;
        const { id } = activity.sys;

        if (!data) {
          log(`No data for "${title}"`);
          return;
        }
        const sourceId = `activity-${activity.sys.id}`;

        log(`Creating layers for "${title}" (${id})`);

        layersToAdd.push({
          id: `${sourceId}-line`,
          type: "line",
          source: sourceId,
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "orange",
            "line-width": 3,
            "line-dasharray": [2, 3],
          },
        });

        // layers.push({
        //   id: `${sourceId}-icon`,
        //   type: "symbol",
        //   source: sourceId,
        //   layout: {
        //     "symbol-placement": "line",
        //     "symbol-spacing": 60,
        //     "icon-allow-overlap": true,
        //     "icon-image": activityMarkers[activity.fields.type],
        //     "icon-size": 0.5,
        //     "icon-rotation-alignment": "viewport",
        //     // visibility: "visible",
        //   },
        //   paint: {
        //     "icon-color": "orange",
        //   },
        // });

        if (map.getSource(sourceId)) {
          log(`Source exists for "${title}" (${sourceId})`);
          return;
        }

        log(`Creating source for "${title}" (${id})`);
        const geoJson = await await fetch(activity.data.url).then((res) =>
          res.json()
        );

        sources[sourceId] = {
          type: "geojson",
          data: geoJson,
        };
      }
    }

    populateFeatures().then(() => {
      Object.entries(sources).forEach(([id, data]) => {
        log(`Adding ${id} source to map`);
        map.addSource(id, data);
      });

      layersToAdd.forEach((layer) => {
        log(`Adding ${layer.id} layer to map`);
        map.addLayer(layer);
        layersToRemove.push(layer);
      });
    });

    return () => {
      layersToRemove.forEach((layer) => {
        log(`Removing layer ${layer.id} from map`);
        map.removeLayer(layer.id);
      });
    };
  }, [entry, map]);
}

export const Explorer: React.ComponentType<ExplorerProps> = ({
  journal,
  navigate,
}) => {
  const params = useParams<{ entryDate: string }>();
  const entryDate = DateTime.fromISO(params.entryDate, { zone: "utc" });
  const entryId = journal.find((entry) => entry.date === entryDate.toISO()).sys
    .id;

  const { data, loading, error } = useQuery<
    ExplorerQuery,
    ExplorerQueryVariables
  >(EXPLORER_QUERY, { variables: { entryId } });

  if (error) {
    throw error;
  }

  const [tab, setTab] = useState<TabNames>("instagram");
  const tabValue = tabs.indexOf(tab);
  const header = useHeader(entryId);

  const entry = loading ? undefined : data.journalEntry;

  useMapBounds(entry);
  useActivities(entry);

  useEffect(() => {
    setTab("instagram");
  }, [entryId]);

  // useActivities(entry, (id) => explorer.selectActivity(id));

  // const tabValue = explorer.tab ? tabs.indexOf(explorer.tab as TabNames) : 0;

  if (loading) {
    return null;
  }

  const tabElements: { [key in TabNames]: TabElements } = {
    accommodation: accommodationTab,
    activities: activitiesTab,
    instagram: instagramTab,
    notes: notesTab,
  };

  const onNext = () => {
    const entryIndex = journal.findIndex((e) => e.sys.id === entry.sys.id);
    navigate(journal[entryIndex + 1].sys.id);
  };
  const onPrevious = () => {
    const entryIndex = journal.findIndex((e) => e.sys.id === entry.sys.id);
    navigate(journal[entryIndex - 1].sys.id);
  };

  return (
    <>
      <DateNavigation
        date={entry.date}
        onNext={onNext}
        onPrevious={onPrevious}
      />
      <div data-joyride="explorer">
        <Box py={2}>
          <Typography variant="h4">{header}</Typography>
        </Box>
        <Tabs
          value={tabValue}
          onChange={(_, value) => setTab(tabs[value])}
          variant="fullWidth"
        >
          {tabs.map((key) => {
            const { icon, badge } = tabElements[key];
            const iconElement = badge ? (
              <Badge badgeContent={badge(entry)} color="primary">
                {icon}
              </Badge>
            ) : (
              icon
            );
            return <StyledTab key={key} icon={iconElement} />;
          })}
        </Tabs>
      </div>
      {tabs.map((key) => {
        const { content } = tabElements[key];

        return content
          ? React.cloneElement(content(tabValue, entry), { key })
          : null;
      })}
    </>
  );
};
