import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAccount } from "../../App";
import { TripCard } from "../../components/TripCard";
import { TripSummaryModel } from "../../modules/model/v1";
import { slugify } from "../../modules/routing";

export default function HomeRoute() {
  const navigate = useNavigate();
  const trips = useAccount().getTripSummaries();

  const tripUrl = (trip: TripSummaryModel) =>
    `/trips/${trip.getVersion()}/${slugify(trip.getName())}`;

  return (
    <>
      <Box mb={2}>
        <Typography component="h2" variant="h4">
          Trips
        </Typography>
      </Box>
      {trips.map((trip) => (
        <Box key={trip.getId()} mb={2}>
          <TripCard
            tripId={trip.getId()}
            actions={
              <Button
                size="small"
                onClick={() =>
                  navigate(
                    tripUrl(trips.find((t) => t.getId() === trip.getId()))
                  )
                }
                {...(trip.getName() === "Europe 2019"
                  ? { "data-joyride": "go-to-trip" }
                  : {})}
              >
                Go to Trip
              </Button>
            }
          />
        </Box>
      ))}
    </>
  );
}
