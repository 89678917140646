import { decode } from "@mapbox/polyline";
import debug from "debug";
import { AnyLayer, AnySourceData, GeoJSONSource, Map, Marker } from "mapbox-gl";
import { MapUtils } from "../../../App";
import { MAPBOX_ACCESS_TOKEN } from "../../../clients/mapbox";
import { getBounds } from "../../../utils";
import {
  TripRouteQuery,
  TripRouteQuery_activityCollection_items,
  TripRouteQuery_journalEntryCollection_items_accommodation,
} from "./__generated__/TripRouteQuery";

const sources = {
  accommodations: {
    id: "accommodations",
  },
  route: {
    id: "route-line",
  },
  activities: {
    id: "activites",
  },
};

const layers = {
  "accommodation-points": {
    id: "accommodation-points",
  },
  "route-line": {
    id: "route-line",
  },
  "route-arrow": {
    id: "route-arrow",
  },
  "activity-points": {
    id: "activity-points",
  },
};

const log = debug("mbf:trips:renderTrip");

export function getAccommodationsData(
  accommodations: TripRouteQuery_journalEntryCollection_items_accommodation[]
): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
  return {
    type: "FeatureCollection",
    features: accommodations.map((item, index) => {
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [item.location.lon, item.location.lat],
        },
        properties: {
          name: item.name,
          contentId: item.sys.id,
          locationId: item.locationRef.sys.id,
          isFirst: index === 0,
          isLast: index === accommodations.length - 1,
        },
      };
    }),
  };
}

export function getActivityData(
  activities: TripRouteQuery_activityCollection_items[]
): GeoJSON.FeatureCollection<GeoJSON.Geometry> {
  return {
    type: "FeatureCollection",
    features: activities.map((item) => {
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [item.location.lon, item.location.lat],
        },
        properties: {
          // name: item.name,
          // contentId: item.sys.id,
          // locationId: item.locationRef.sys.id,
        },
      };
    }),
  };
}

interface Segment {
  route: boolean;
  coordinates: Array<[number, number]>;
}

const createSegment = (
  locations: Array<{ lat: number; lon: number }>,
  route = true
): Segment => ({
  route,
  coordinates: locations.map(({ lat, lon }) => [lon, lat]),
});

async function getDirections(coordinates: any[], profile = "driving") {
  const params = [
    "geometries=polyline",
    `access_token=${MAPBOX_ACCESS_TOKEN}`,
    "overview=full",
    "steps=true",
    "exclude=ferry",
  ].join("&");
  const response = await fetch(
    `https://api.mapbox.com/directions/v5/mapbox/${profile}/${coordinates.join(
      ";"
    )}?${params}`
  );
  return response.json();
}

export async function getRouteData(
  accommodations: TripRouteQuery_journalEntryCollection_items_accommodation[]
): Promise<GeoJSON.FeatureCollection<GeoJSON.Geometry>> {
  const log = debug("mbf:trips:createRouteLayer");

  // A segment contains a set of co-ordinates and an instruction to either
  // drive between them or draw straight lines
  const segments: Segment[] = [];

  accommodations
    // We start at the second location
    .slice(1)
    .forEach((item, i) => {
      // item is i+1 in accommodations
      const previous = accommodations[i];

      // The first location in each segment should always be the
      // last location in the previous segment
      let locations = [previous.parking || previous.location];

      item.waypointsCollection.items.forEach((waypoint) => {
        if (waypoint.direct) {
          if (locations.length < 2) {
            segments.push(
              createSegment(locations.concat(waypoint.location), false)
            );
          } else {
            // Make a segment from the locations we've accumulated so far
            segments.push(createSegment(locations));

            // Add a direct segment between 2 points
            segments.push(
              createSegment([locations.pop(), waypoint.location], false)
            );
          }

          // Reset locations to start from this waypoint
          locations = [waypoint.location];
        } else {
          // If a waypoint is not direct we can just push it
          locations.push(waypoint.location);
        }
      });

      locations.push(item.parking || item.location);

      segments.push(createSegment(locations));
    });

  log("segments", segments);

  const routes = await Promise.all(
    segments.map(async (segment) => ({
      ...segment,
      directions: segment.route
        ? await getDirections(segment.coordinates)
        : undefined,
    }))
  );

  log("routes", routes);

  const features: GeoJSON.Feature<GeoJSON.Geometry>[] = [];

  routes.forEach((res) => {
    const { directions } = res;

    if (directions) {
      if (directions.code !== "Ok") {
        console.warn(res);
        throw new Error(
          `Bad route; ${directions.message} (${directions.code})`
        );
      }

      const {
        routes: [route],
      } = directions;

      features.push({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: decode(route.geometry, 5).map((c) => c.reverse()),
        },
        properties: {},
      });

      route.legs.forEach((leg: any) =>
        leg.steps.forEach((step: any) => {
          if (step.maneuver.type === "waypoint") {
            features.push({
              type: "Feature",
              geometry: step.maneuver.location,
              properties: {
                id: "waypoint",
              },
            });
          }
        })
      );
    } else {
      features.push({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: res.coordinates,
        },
        properties: {},
      });
    }
  });

  log("features", features);

  return {
    type: "FeatureCollection",
    features,
  };
}

interface RenderTripResult {
  ready: Promise<void>;
  reset: () => void;
}

let prepared: boolean = false;

async function prepare(map: Map) {
  if (prepared) {
    return;
  }

  function addSource(id: string, source: AnySourceData) {
    if (map.getSource(id) === undefined) {
      log("Adding source", id);
      map.addSource(id, source);
    }
  }

  function addLayer(layer: AnyLayer, beforeLayerId?: string) {
    if (map.getLayer(layer.id) === undefined) {
      log("Adding layer", layer.id);
      map.addLayer(layer, beforeLayerId);
    }
  }

  addSource(sources.accommodations.id, {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });

  // Render an icon on each accommodation, except the first and last, where we put
  // start and finish icons
  addLayer({
    id: layers["accommodation-points"].id,
    type: "symbol",
    source: sources.accommodations.id,
    layout: {
      "icon-image": "lodging-15",
      "icon-allow-overlap": true,
    },
    filter: ["all", ["!", ["get", "isFirst"]], ["!", ["get", "isLast"]]],
  });

  addSource(sources.route.id, {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });

  // Render the paths between each accommodation as a line
  addLayer(
    {
      id: layers["route-line"].id,
      type: "line",
      source: sources.route.id,
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#486DE0",
        "line-width": 3,
        "line-dasharray": [4, 4],
      },
    },
    "accommodation-points"
  );

  // Add a direction arrow to the route line
  addLayer({
    id: layers["route-arrow"].id,
    type: "symbol",
    source: sources.route.id,
    layout: {
      "symbol-placement": "line",
      "symbol-spacing": 60,
      "icon-allow-overlap": true,
      // 'icon-ignore-placement': true,
      "icon-image": "arrow",
      "icon-size": 0.5,
      visibility: "visible",
    },
  });

  addSource(sources.activities.id, {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });

  addLayer({
    id: layers["activity-points"].id,
    type: "symbol",
    source: sources.activities.id,
    layout: {
      "icon-image": "attraction-15",
      "icon-allow-overlap": true,
    },
    minzoom: 4,
  });

  if (!map.hasImage("arrow")) {
    await new Promise<void>((resolve) =>
      map.loadImage(
        "/arrow.png",
        function (err: Error | undefined, image: any) {
          map.addImage("arrow", image);
          resolve();
        }
      )
    );
  }

  prepared = true;
}

export function renderTrip(
  { map, resetMapPosition }: MapUtils,
  data: TripRouteQuery
): RenderTripResult {
  log("getAccommodations...");
  const accommodations = data.journalEntryCollection.items
    .map((journalEntry) => journalEntry.accommodation)

    .sort(
      (a, b) => new Date(a!.checkIn).getTime() - new Date(b!.checkOut).getTime()
    );

  log("accommodations", accommodations);

  const activities = data.activityCollection.items;

  // function selectAccommodation(id: string) {
  //   const entry = accommodations.find((a) => a.sys.id === id);

  //   if (!entry) {
  //     throw new Error("Accommodation not found");
  //   }

  //   (map.getSource("selected-item") as GeoJSONSource).setData({
  //     type: "FeatureCollection",
  //     features: [
  //       {
  //         type: "Feature",
  //         geometry: {
  //           type: "Point",
  //           coordinates: [entry.location.lon, entry.location.lat],
  //         },
  //         properties: {},
  //       },
  //     ],
  //   });

  //   // store.explorer.selectLocation(entry.fields.locationRef.id);
  // }

  // map.addSource("selected-item", {
  //   type: "geojson",
  //   data: {
  //     type: "FeatureCollection",
  //     features: [],
  //   },
  // });

  // map.addLayer({
  //   id: "selected-item",
  //   type: "circle",
  //   source: "selected-item",
  //   paint: {
  //     "circle-radius": 12,
  //     "circle-color": "#486DE0",
  //   },
  // });

  const ready = (async function () {
    await prepare(map);

    (map.getSource(sources.accommodations.id) as GeoJSONSource).setData(
      getAccommodationsData(accommodations)
    );

    (map.getSource(sources.route.id) as GeoJSONSource).setData(
      await getRouteData(accommodations)
    );

    (map.getSource(sources.activities.id) as GeoJSONSource).setData(
      await getActivityData(activities)
    );
  })();

  const bounds = getBounds(accommodations.map((a) => a.location));
  map.fitBounds(bounds, {
    padding: 96,
  });

  // map.on("click", (e) => {
  //   const features = map.queryRenderedFeatures(e.point);

  //   const activityIcon = features.find(
  //     (f) => f.layer.id.startsWith("activity") && f.layer.id.endsWith("icon")
  //   );

  //   if (activityIcon) {
  //     // store.explorer.setTab("activities");
  //     return;
  //   }

  //   const accommodation = features.find(
  //     (f) => f.layer.id === "accommodation-points"
  //   );

  //   if (!accommodation) {
  //     return;
  //   }

  //   selectAccommodation(accommodation.properties.contentId);
  // });

  const startEl = document.createElement("div");
  startEl.className = "far fa-play-circle fa-2x";
  startEl.style.color = "#6e5949";
  // startEl.onclick = (e) => {
  //   e.stopPropagation();
  //   selectAccommodation(accommodations[0].sys.id);
  // };

  const startMarker = new Marker(startEl)
    .setLngLat(accommodations[0].location)
    .addTo(map);

  const finishEl = document.createElement("div");
  finishEl.className = "fas fa-flag-checkered fa-2x";
  finishEl.style.color = "#6e5949";
  // finishEl.onclick = (e) => {
  //   e.stopPropagation();
  //   selectAccommodation(accommodations[accommodations.length - 1].sys.id);
  // };

  const finishMarker = new Marker(finishEl)
    .setLngLat(accommodations[accommodations.length - 1].location)
    .addTo(map);

  const reset = () => {
    console.log("Resetting trip");
    Object.values(sources).forEach(({ id }) =>
      (map.getSource(id) as GeoJSONSource).setData({
        type: "FeatureCollection",
        features: [],
      })
    );
    Object.values(layers).forEach(({ id }) => map.removeLayer(id));
    finishMarker.remove();
    startMarker.remove();
    resetMapPosition();
  };

  return {
    ready,
    reset,
  };
}
